import React from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson'
import classes from './ResponsiveMap.scss'
import PropTypes from 'prop-types';

export class ResponsiveMap extends React.Component {

  componentDidMount () {
    this._drawGraph(this.props)
  }

  componentWillReceiveProps (nextProps) {
    let firstGeo = getValue(this.props, 'geo', 'objects', 'collection', 'geometries', '0', 'id')
    let nextFirstGeo = getValue(nextProps, 'geo', 'objects', 'collection', 'geometries', '0', 'id')
    let firstChild = getValue(this.props, 'children', 'objects', 'collection', 'geometries', '0', 'id')
    let nextChild = getValue(nextProps, 'children', 'objects', 'collection', 'geometries', '0', 'id')
    if (firstGeo !== nextFirstGeo || firstChild !== nextChild) {
      this._drawGraph(nextProps)
    }
  }

  _drawGraph (props) {
    if (!props.geo) return
    let geo = props.geo.type &&
      props.geo.type === 'FeatureCollection'
      ? props.geo : topojson.feature(props.geo, props.geo['objects']['collection'])
    // console.log('_drawGraph before convert', geo)
    
    let childrenGeo = null
    // console.log('_drawGraph', geo)
    if (props.children) {
      // let newChild = Object.assign({}, props.children)
      childrenGeo = props.geo.type &&
        props.children.type === 'FeatureCollection'
        ? props.children : topojson.feature(props.children, props.children['objects']['collection'])
    }
    let divName = this.props.mapDiv || 'mapDiv'
    let width = document.getElementById(divName).offsetWidth
    let height = width * 0.6

    var projection = d3.geoAlbers()

    var path = d3.geoPath()
      .projection(projection)

    projection
        .scale(1)
        .translate([0, 0])

    let b = path.bounds(geo)
    let s = 0.95 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height)
    let t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2]

    projection
        .scale(s)
        .translate(t)

    let svg = d3.select('#' + divName + ' svg')
    .attr('viewBox', '0 0 ' + width + ' ' + height)

    svg.selectAll('path').remove()

    svg.selectAll('.state')
      .data(geo.features)
      .enter().append('path')
      .attr('class', 'state')
      .attr('d', path)

    if (props.children) {
      svg.selectAll('.msa')
        .data(childrenGeo.features)
        .enter().append('path')
        .attr('class', classes['msa'])
        // .attr('id',function(d){return 'msa'+d.properties.id})
        .attr('fill', props.childColor || '#7EC0EE')
        .attr('d', path)
        .on('click', props.click || null)
        .on('mouseover', props.mouseover || null)
        .on('mouseout', props.mouseout || null)
    }
  }

  render () {
    let divName = this.props.mapDiv || 'mapDiv'
    return (
      <div id={divName} className={classes['svg-container']}>
        <svg className={classes['.svg-content-responsive']} preserveAspectRatio='xMinYMin meet'/>
      </div>
    )
  }
}

ResponsiveMap.propTypes = {
  geo: PropTypes.object,
  children: PropTypes.object,
  mapDiv: PropTypes.string
}

export default ResponsiveMap

function getValue (obj) {
  var args = Array.prototype.slice.call(arguments, 1)

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return null
    }
    obj = obj[args[i]]
  }
  return obj
}
