import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import { Link } from 'react-router-dom'

import './Candidate.css'


import get from 'lodash.get'


class Candidate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.expandedToggle = this.expandedToggle.bind(this)
  }

  fetchFalcorDeps () {
    return this.props.falcor.get(
      ['personsById', this.props.candidateId, ['name', 'address', 'party', 'phones', 'urls', 'photoUrl', 'channels', 'id']],
      ['personsById', this.props.candidateId, 'campaign', this.props.year, 'office', this.props.officeId, 'campaign_data']
    )
  }

  expandedToggle () {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  renderParts (campaign) {
    var pressCoverage = campaign.press && campaign.press.length > 0
      ? ( 
        <div className="ringer-coverage">
          <h5>Press Coverage</h5>
          <ul>
            {
              campaign.press.map(pressItem => {
                return (
                  <li>
                    <a href={pressItem.url} target="_blank" rel="noopener noreferrer">
                      {pressItem.title} 
                      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 13.03">
                        <title>newarrow</title>
                        <rect className="white-fill" y="5.75" width={15} height="1.5" />
                        <rect className="white-fill" x="22.59" y="12.58" width="1.5" height="8.15" transform="translate(-16.69 8.13) rotate(-45)" />
                        <rect className="white-fill" x="19.26" y="22.11" width="8.15" height="1.5" transform="translate(-21.08 9.95) rotate(-45)" />
                      </svg>
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      ) 
      : <span />

    var pitch = campaign.pitch 
      ? (
          <div className="player-description">
              <div className="player-headline">{campaign.pitch}</div>
              <div className="player-subline"><strong>TOP ISSUES: </strong>{campaign.issues.join(', ')}</div>
          </div>
        )
      : <span />

    var video = campaign.video  
      ? ( <div className="player-stat-image">
          <h6>CAMPAIGN VIDEO</h6>
          <div className='videoWrapper'>
            <iframe title='campaignVideo' width="560" height="330" src={campaign.video} frameborder="0">
            </iframe>
          </div>
        </div> 
      ) : <span />

    console.log('platform crashing', campaign.platform)
    var platform = campaign.platform 
      ? (
          <span>
            <h6>PLATFORM</h6>
            <ul>
              {
                campaign.platform.split('\n')
                  .filter((d,i) => i < 10)
                  .map(item => {
                  return (
                    <li>
                      {item}
                    </li>
                  )
                })
              }
            </ul>
          </span>
      ) : <span />

     var endorsements = campaign.endorsements 
      ? (
          <span>
            <h6>ENDORSEMENTS</h6>
            <ul>
              {
                campaign.endorsements
                  .filter((d,i) => i < 10)
                  .map(item => {
                  return (
                    <li>
                      "{item.quote}"<br />
                      <span style={{fontSize: '1em'}}>{item.name} - {item.role}</span>
                    </li>
                  )
                })
              }
            </ul>
          </span>
      ) : <span />

    return {
      pressCoverage,
      video,
      pitch,
      platform,
      endorsements
    }
  }

  render () {
    // let race = this.props.race
    let candidate = this.props.candidate
    
    console.log(`<candidate ${this.props.candidateId}>`, this.props.campaign)

    let parts = this.props.campaign ? this.renderParts(this.props.campaign) : ''
    let photoUrl = this.props.campaign &&  this.props.campaign.photo ? this.props.campaign.photo : candidate.photoUrl ? candidate.photoUrl : null
   
    let expanded =  this.state.expanded 
      ? 'card-item col-xs-12 expanded-card' 
      : 'card-item col-xs-12' 

    return (
     <li className={expanded} style={{cursor: 'default'}}>
        
        <div className="wrapper">
          <div className="rank-column background-theme" >
            <div className="rank"></div>
            <div className="nba-team-wrapper"><span>{candidate.party}</span>
            </div>
            <span className="block h-48 w-48 md:h-40 md:w-40 mx-auto border-2 border-gray-800 rounded-full overflow-hidden bg-blue-100 bg-cover" style={{backgroundImage: `url(${photoUrl})`}}>                
                {photoUrl ?  '' :
                (<svg className="h-full w-full text-blue-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>)
                }
              </span>
            {parts.pressCoverage}
          </div>
          <div className="info-column" style={{backgroundColor: '#fefefe'}}>
              <div className="medium-show">
                  <div className="player-main">
                      <h3><Link to={`/race/${this.props.officeId}/${this.props.year}/${candidate.id}`}>{candidate.name}</Link></h3>
                      <h4>{candidate.party}&nbsp;</h4>
                      <h4>Candidate</h4>
                  </div>
                  <div className="player-stats">
                    <ul>
                        <li>
                          <div className="stat-wrap">
                            <span className="title">WWW</span>
                            <span className="number">
                              <i className="icon-globe"></i>
                            </span>
                            <span className="detail color-theme">website</span>
                          </div>
                        </li>
                        <li>
                          <div className="stat-wrap">
                            <span className="title">FB</span>
                            <span className="number">
                              {
                                candidate.channels && candidate.channels['facebook']  
                                ? (
                                    <a href={candidate.channels['facebook']}>
                                    <i className="icon-social-facebook"></i>
                                    </a>
                                  )
                                : <i className="icon-social-facebook"></i> 
                              }
                            </span>
                            <span className="detail color-theme">facebook</span>
                          </div>
                        </li>
                        <li>
                          <div className="stat-wrap">
                            <span className="title">TWTR</span>
                             <span className="number">
                              {
                                 candidate.channels && candidate.channels['twitter']  
                                ? (
                                    <a href={candidate.channels['twitter']}>
                                    <i className="icon-social-twitter"></i>
                                    </a>
                                  )
                                : <i className="icon-social-twitter"></i> 
                              }
                            </span>
                            <span className="detail color-theme">twitter</span>
                          </div>
                        </li>
                        <li>
                          <div className="stat-wrap">
                            <span className="title">YT</span>
                            <span className="number">
                              {
                                candidate.channels && candidate.channels['youtube']  
                                ? (
                                    <a href={candidate.channels['youtube']}>
                                    <i className="icon-social-youtube"></i>
                                    </a>
                                  )
                                : <i className="icon-social-youtube"></i> 
                              }
                            </span>
                            <span className="detail color-theme">youtube</span>
                          </div>
                        </li>
                    </ul>
                  </div>
                  <div className="player-meta">
                     
                  </div>
                  {parts.pitch}
              </div>
              <div className="player-info">
                  {parts.video}
                  <div className="player-plus-minus">
                      {parts.platform}
                      {parts.endorsements}
                  </div>
                  <div className="player-social">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://nbadraft.theringer.com?player=m_fultz" rel="noopener noreferrer" target="_blank" className="facebook">Share To Facebook</a>
                    <a href="https://twitter.com/intent/tweet?url=http://nbadraft.theringer.com/?player=m_fultz&text=Everything you need to know about Markelle Fultz from @ringer’s 2017 NBA Draft Guide" target="_blank" rel="noopener noreferrer" className="twitter">Share To Twitter</a>
                  </div>
              </div>
          </div>
          <div className="toggle-card" onClick={this.expandedToggle}>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.5 39.5">
              <defs>
                  <clippath id="clip-path">
                      <rect className="cls-1" width="39.5" height="39.5" />
                  </clippath>
              </defs>
              <title>newarrow</title>
              <g className="cls-2">
                  <path className="arrow-fill" d="M19.75,39.5A19.75,19.75,0,1,1,39.5,19.75,19.75,19.75,0,0,1,19.75,39.5Zm0-38A18.25,18.25,0,1,0,38,19.75,18.25,18.25,0,0,0,19.75,1.5Z" />
              </g>
              <rect className="arrow-fill" x={19} y="11.75" width="1.5" height={15} />
              <rect className="arrow-fill" x="18.77" y="22.59" width="8.15" height="1.5" transform="translate(-9.81 22.99) rotate(-45)" />
              <rect className="arrow-fill" x="15.89" y="19.26" width="1.5" height="8.15" transform="translate(-11.63 18.6) rotate(-45)" />
            </svg>
          </div>
        </div>
      </li>
    )
  }
}

function select (state, props) {
  return {
    // user: state.user.toJS(),
    candidate: get(state.graph, `personsById[${props.candidateId}]`,{}),
    campaign: get(state.graph, `personsById[${props.candidateId}].campaign[${props.year}].office[${props.officeId}].campaign_data.value`, null) 
  }
}

export default connect(select)(reduxFalcor(Candidate))


