import React from "react"
import { connect } from 'react-redux'
import SidebarItem from './SideBarItem'

import { 
	HeroHomeOutline, 
	// PlusCircleOutline, 
	UserGroupOutline, 
	// SearchOutline, 
	// Folder, 
	// Calendar, 
	// Envelope , 
	// User , 
	// Bell
} from '../HeroIcons'

let config = [
	{
		name: 'Dashboard',
		path: '/candidate/dashboard',
		active: true,
		icon: HeroHomeOutline
	},
	{
		name: 'Campaigns',
		path: '/candidate/campaigns',
		icon: UserGroupOutline
	},
	// {
	// 	name: '',
	// 	path: '/candidate/campaigns'
	// },
	// {
	// 	name: 'Reports',
	// 	path: '/candidate/campaigns',
	// 	icon: Envelope
	// }
]

let adminConfig = [
	// {
	// 	name: 'Admin Dashboard',
	// 	path: '/admin/dashboard',
	// 	active: true,
	// 	icon: HeroHomeOutline
	// },
	{
		name: 'Election Manager',
		path: '/admin/elections',
		icon: UserGroupOutline
	},
	{
		name: 'Signup Manager',
		path: '/admin/signups',
		icon: UserGroupOutline
	},
	// {
	// 	name: 'Reports',
	// 	path: '/candidate/campaigns',
	// 	icon: Envelope
	// }
]

const MobileSidebar = (props) => (
	<div style={{display: props.open ? 'block' : 'none' }} x-show="sidebarOpen" className="md:hidden">
	    <div click="sidebarOpen = false" x-show="sidebarOpen" x-transition_enter-start="opacity-0" x-transition_enter-end="opacity-100" x-transition_leave-start="opacity-100" x-transition_leave-end="opacity-0" className="fixed inset-0 z-30 transition-opacity ease-linear duration-300">
	      <div className="absolute inset-0 bg-gray-600 opacity-75" />
	    </div>
	    <div  className="fixed inset-0 flex z-40">
	      <div  className="flex-1 flex flex-col max-w-xs w-full transform ease-in-out duration-300" style={{backgroundColor: '#f0f7f9'}}>
	        <div className="absolute top-0 right-0 -mr-14 p-1">
	          <button onClick={props.toggle} className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600">
	            <svg className="h-6 w-6 text-gray-900" stroke="currentColor" fill="none" viewBox="0 0 24 24">
	              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
	            </svg>
	          </button>
	        </div>
	        <div className="flex-1 h-0 pt-2 pb-4 overflow-y-auto">
	          <a href='/' className='px-6 pb-12 logo-text gray-900 text-blue-900' style={{fontFamily: 'TIMMONS NY', fontSize: '2em', textDecoration: 'none'}}>
          		<img alt='Logo' src="/img/ktc_logo_sq.png" style={{height: 35, display: 'inline', paddingRight: 5, paddingBottom: 5}}/>
          		KNOW YOUR CANDIDATE
          	  </a>
	          <nav className="px-2">
	            {config.map((page,i) => {
            		return (
            			<SidebarItem key={i} to={page.path}  icon={page.icon}>
        					{page.name}
      					</SidebarItem>
            		)
           		})}
	          </nav>

	          <nav className="px-2 pt-5">
	            {adminConfig.map((page,i) => {
            		return (
            			<SidebarItem key={i} to={page.path}  icon={page.icon}>
        					{page.name}
      					</SidebarItem>
            		)
           		})}
	          </nav>
	        </div>
	       
	      </div>
	      <div className="flex-shrink-0 w-14">
	        {/* Force sidebar to shrink to fit close icon */}
	      </div>
	    </div>
  </div>
) 

const DesktopSidebar = (props) => (
	<div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-56">
        <div style={{position: 'fixed', top: '0px', zIndex: 4}} className="w-56 flex-1 flex flex-col pb-4 overflow-y-auto ">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <a href='/' className='px-6 pt-3 pb-3 logo-text gray-900 text-blue-900' style={{fontFamily: 'TIMMONS NY', fontSize: '2em',  textDecoration: 'none'}}>
          	<img  alt='Logo' src="/img/ktc_logo_sq.png" style={{height: 35, display: 'inline', paddingRight: 5, paddingBottom: 5}}/>
          	KNOW THE CANDIDATES
          </a>
          <nav className="flex-1 px-2">
            {config.map((page,i) => {
            	return (
            		<SidebarItem key={i} to={page.path}  icon={page.icon}>
        				{page.name}
      				</SidebarItem>
            	)
           	})}
          </nav>

          <nav className="px-2">
	            {adminConfig.map((page,i) => {
            		return (
            			<SidebarItem key={i} to={page.path}  icon={page.icon}>
        					{page.name}
      					</SidebarItem>
            		)
           		})}
	          </nav>
        </div>
       
      </div>
    </div>
)

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

let MobileSidebarConnected =  connect(mapStateToProps, mapDispatchToProps)(MobileSidebar)
let DesktopSidebarConnected =  connect(mapStateToProps, mapDispatchToProps)(DesktopSidebar)


export default (props) => (
	<>
		<MobileSidebarConnected {...props} />
		<DesktopSidebarConnected {...props} />
	</>
)
