import React from 'react'
import { Link } from 'react-router-dom'

function KYCNav (props) {
  return (
    <header style={{ backgroundColor: '#f0f7f9'}}>
      <nav style={{ backgroundColor: '#f0f7f9', color: '#000', maxWidth:'1390px', margin: '0 auto'}}>
            <Link className='logo-text text-blue-900' to='/' style={{textDecoration: 'none'}}>
              <img alt='Logo' src="/img/ktc_logo_sq.png" style={{height: 35, display: 'inline', paddingRight: 5, paddingBottom: 5}}/>
              KNOW THE CANDIDATES
            </Link>
            
            <ul
              className="main-nav"
              style={{ marginBottom: 10, paddingBottom: 0 }}
            >
              <li>
                <a
                  href="/about"
                  style={{ color: 'rgb(0, 91, 204)' }}
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  style={{ color: 'rgb(0, 91, 204)' }}
                >
                  FAQ
                </a>
              </li>
            </ul>
          
          </nav>
      
      
     
    </header>
  )
}

export default KYCNav
