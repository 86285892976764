import React from 'react'
import AvatarEditor from './AvatarEditor'
import { host } from 'store/modules/falcorGraph'
import Loading from 'components/util/Loading'

class ImageEditor extends React.Component {
  constructor () {
    super()
    this.state = {
      zoom: 1,
      saving: false,
      error: null
    }
    this._zoom = this._zoom.bind(this)
    this._handleSave = this._handleSave.bind(this)
  }

  _zoom (e) {
    this.setState({
      zoom: e.target.value
    })
  }

  _handleSave () {
    var rect = this.refs.avatar.getCroppingRect()
    // console.log('save',rect,  rect.width * rect.oWidth, rect.height * rect.oHeight, Math.round(rect.x * rect.oWidth) , Math.round(rect.y * rect.oHeight))

    let args = 'image="' + encodeURI(this.props.image) +
      '"&width=' + Math.round(rect.width * rect.oWidth) +
      '&height=' + Math.round(rect.height * rect.oHeight) +
      '&x=' + Math.round(rect.x * rect.oWidth) +
      '&y=' + Math.round(rect.y * rect.oHeight)
    
    console.log('args', host + '/img/process/?' + args)
    this.setState({
      saving: true
    })

    fetch(host + '/img/process/?' + args)
    .then(response => response.json())
    .then(json => {
      if (json.errorMessage) {
        this.setState({
          saving: false,
          error: json.errorMessage
        })
      } else {
        if (this.props.onSave) {
          this.props.onSave(json.src.url)
        }
        this.setState({
          saving: false,
          error: null
        })
      }
    })
    .catch((e) => console.log("Can’t access " + host + '/img/process/?' + args + " response. Blocked by browser?", e))
  }

  render () {
    return (
      <div>
        <div style={{width: 550, margin: '0 auto'}}>
        {this.state.error}
        <AvatarEditor
          ref='avatar'
          image = {this.props.image}
          width = {250}
          height = {250}
          border = {150}
          color = {[0, 0, 0, 0.6]} // RGBA
          scale = {+this.state.zoom} />
          </div>
          <div className='row' style={{marginTop: -100}}>
            <div className='col-xs-2 col-xs-offset-5' style={{textAlign: 'center'}}>
              {this.state.zoom} <br />
              <input name='scale' type='range' min='1' max='2' step='0.01' value={this.state.zoom} onChange={this._zoom} />
              {this.state.saving ? <Loading /> : ''} {/*<button className='btn btn-default' onClick={this._handleSave}> Set </button>} */}
            </div>
          </div>
      </div>
    )
  }
}

// ImageEditor.propTypes = {
//   image: React.PropTypes.string,
//   type: React.PropTypes.string,
//   typeId: React.PropTypes.number,
//   onSave: React.PropTypes.func
// }

export default ImageEditor

