import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as graph } from 'utils/redux-falcor';


import user from './user';
import messages from './messages';

export default combineReducers({
  user,
  messages,
  graph,
  router: routerReducer
});
