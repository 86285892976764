//import React from "react"
import MapLayer from "components/AvlMap/MapLayer"
import mapboxgl from "mapbox-gl";
//import { falcorGraph } from "store/modules/falcorGraph"


//import get from "lodash.get"
// import styled from "styled-components"
// import * as d3scale from 'd3-scale'

var d3 = require('d3-geo')
var R = 6378137.0 // radius of Earth in meters
const projections = {
    'albersUsa': d3.geoAlbersUsa().translate([0, 0]).scale(R),
    'mercator' : d3.geoMercator().translate([0, 0]).scale(R)
}

const point2Albers = (lng, lat) => {
    console.log('point2Albers', lng, lat)
    let data =  projections['mercator'].invert(projections['albersUsa']([lat,lng]))
    let out = data ? data : [0,0]
    return out
}

const click2Mercator = function (lat, lng) {
   
    let data = projections['albersUsa'].invert(projections['mercator']([lat,lng]))
    let out = data ? data : false
    return out
}

class DivisionsLayer extends MapLayer {
  onAdd(map) {
    this.map = map
     this.marker =  new mapboxgl.Marker({
        draggable: false,
        color: '#005bcc'
        })
        .setLngLat([-28,24])
        .addTo(this.map)
        
        this.map.on('click', d => {
       
            // this.marker
            //     .setLngLat(d.lngLat)
            console.log(click2Mercator(d.lngLat.lng,d.lngLat.lat))
            this.component.props.onChange(click2Mercator(d.lngLat.lng,d.lngLat.lat).reverse())

        })

  }

  receiveProps (oldProps, props) {
   
    if(oldProps.latLng[0] !== props.latLng[0] || oldProps.latLng[1] !== props.latLng[1]) {
        //console.log('update pin', props.latLng, point2Albers(...props.latLng))
        console.log('receiveProps', props.latLng)
        this.marker
            .setLngLat(point2Albers(...props.latLng))
    }

  }
  
  fetchData() {
    return Promise.resolve([])
  }
  
  render(map) {
  
  }
}

export default (props = {}) =>
  new DivisionsLayer("divisions", {
    ...props,
    
    // onHover: {
    //   layers: ["counties"]
    // },
    // popover: {
    //   layers: ["counties"],
    //   dataFunc: function({ properties }) {
    //     return [
    //       [ (<h4 className='text-sm text-bold'>{properties.county_name}, {properties.state_abbrev}</h4>)],
    //       [ "geoid", properties.county_fips]
    //     ]
    //   }
    // },
    sources: [
      {
        id: "composite",
        source: {
            "url": "mapbox://lobenichou.albersusa,lobenichou.albersusa-points",
            "type": "vector"
        }
      },
      {
        id: "albersusa",
        source: {
            "url": "mapbox://lobenichou.albersusa",
            "type": "vector"
        }
      }
    ],
    // filters:{ 
    //   'year': {
    //       type: 'dropdown',
    //       value: 'allTime',
    //       domain: [...years,'allTime']
    //   },
    //   'hazard': {
    //       type: 'dropdown',
    //       value: 'riverine',
    //       domain: hazards
    //   }
    // },
    showAttributesModal: false,
    layers: [
      {
            "id": "counties",
            "type": "fill",

            "source": "albersusa",
            "source-layer": "albersusa",
            "filter": ["match", ["get", "type"], ["county"], true, false],
            "layout": {},
            "paint": {
                "fill-color": "hsl(0, 3%, 94%)",
                "fill-opacity": [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  0,
                  1
                ],
                "fill-outline-color":[
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  "hsl(0, 4%, 85%)",
                  "hsl(0, 4%, 85%)"
                ], 
            }
        },
        {
            "id": "state-boundaries",
            "type": "line",
            "source": "composite",
            "source-layer": "albersusa",
            "filter": ["match", ["get", "type"], ["state"], true, false],
            "layout": {},
            "paint": {"line-color": "hsl(0, 0%, 34%)", "line-width": 0.5}
        },
        {
            "id": "county-points",
            "type": "symbol",
            "source": "composite",
            "source-layer": "albersusa-points",
            "filter": ["match", ["get", "type"], ["county"], true, false],
            "layout": {
                "text-field": ["to-string", ["get", "county_fips"]],
                "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"],
                "visibility": "none"
            },
            "paint": {
                "text-color": "hsl(0, 0%, 100%)",
                "text-halo-color": "hsl(0, 0%, 6%)",
                "text-halo-width": 1,
                "text-opacity": ["step", ["zoom"], 0, 6, 1]
            }
        },
        {
            "id": "state-points",
            "type": "symbol",
            "source": "composite",
            "source-layer": "albersusa-points",
            "filter": ["match", ["get", "type"], ["state"], true, false],
            "layout": {
                "text-field": ["to-string", ["get", "state_abbrev"]],
                "text-font": ["Overpass Mono Bold", "Arial Unicode MS Regular"]
            },
            "paint": {
                "text-color": "hsl(0, 0%, 0%)",
                "text-opacity": ["step", ["zoom"], 1, 6, 0],
                "text-halo-color": "hsl(0, 0%, 100%)",
                "text-halo-width": 1
            }
        }
    ]    
   
  })
