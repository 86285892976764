import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import KYCNav from 'components/util/KYCNav'
import Footer from 'components/util/Footer';
import ResponsiveMap from 'components/util/map/ResponsiveMap'
import RaceElections from 'components/election/RaceElections'
import Race from 'components/race/Race'

import get from 'lodash.get'

import getRef from 'utils/falcorGetRef'


class RaceView extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      activeElection: null
    }
    this.setActiveElection = this.setActiveElection.bind(this)
  }

  fetchFalcorDeps (props) {
    props = props ? props.match.params : this.props.match.params
    this.setState({loading: true})
    return this.props.falcor.get(
      ['officesById', props.officeId, 'races', props.year, 'length'],
      ['officesById', props.officeId,
        ['name', 'level', 'term', 'lastElectionYear', 'id']],
      ['officesById', props.officeId, 'organization',['name', 'id']],
      ['officesById', props.officeId, 'division', ['ocdId','geojson', 'name']],
      ['officesById', props.officeId, 'government', ['name', 'id']],
      ['officesById', props.officeId, 'races', props.year, {from: 0, to: 1},
        'election',['id','name','date','type','election_date']]
    ).then(data => {
      this.setState({loading: false})
    })
  }

  shallowProps (props) {
    const officeId = props.match.params.officeId
    const year = props.match.params.year
    const graph = props.graph
    var office = get(graph, `officesById[${officeId}]`, {})
    var officeDivision = getRef(graph, office.division)
    var theGeo = officeDivision.geojson ? officeDivision.geojson.value : null
    let org = getRef(graph,office.organization)
    let general = getRef(graph, getRef(graph,get(office,`races[${props.year}][1]`, {})).election)
    let primary = getRef(graph, getRef(graph,get(office,`races[${props.year}][0]`, {})).election)
    
    return {
      officeId,
      year,
      officeName: office.name ? office.name : '',
      officeTerm: office.term ? office.term : '',
      officeLevel: office.level ? office.level : '',
      officeOrg: get(org , 'name', ''),
      officeGeneralDate: general.date,
      officePrimaryDate: primary.date,
      officeGeo: theGeo,
      divisionName: officeDivision.name || ''
    }
  }

  setActiveElection (electionId) {
    this.setState({activeElection: electionId}) 
  }

  render () {
    // console.log('pages/Race - render', this.state)
    var data = this.shallowProps(this.props)
    var race = this.state.activeElection
    ? <Race
        raceId={this.state.activeElection}
        officeId={data.officeId}
        year={data.year}
      />
    : <span />

    return (
      <div>
        <div style={{backgroundColor: '#f0f7f9', minHeight:'100vh'}}>
          <KYCNav />
            <section>
              <div className='container'>
                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '5em',    justifyContent: 'center'}}>
                  <div style={{flexBasis: 200,}}>
                      <div style={{  paddingTop: 40, 'border': '2px solid black', backgroundColor: '#005bcc',width: 200, height:200,  borderRadius: '50%'}}>
                      <ResponsiveMap
                          mapDiv={'officeMap' + this.officeId}
                          geo={data.officeGeo}
                        />
                      </div>
                  </div>
                  <div style={{flex: 1, zIndex: 99}}>
                      <div style={{paddingTop: 10}}>
                        <h1 style={{fontSize:'5em', lineHeight: 0.78}}>{this.props.match.params.year} {data.officeName} Race</h1>
                        <h2 style={{textAlign: 'center', fontFamily: "GT Pressura Mono", color: '#6e6e6e', lineHeight: 0.8, fontSize: '1.5em'}}>
                          {data.divisionName.toUpperCase()}
                          </h2>
                          <h2 style={{textAlign: 'center', fontFamily: "GT Pressura Mono", color: '#6e6e6e', lineHeight: 0.8, fontSize: '1.5em'}}>
                           {data.officeOrg.toUpperCase()}
                          </h2>
                        <div style={{paddingTop: 25}}>
                           <RaceElections 
                            officeId={this.props.match.params.officeId} 
                            year={this.props.match.params.year} 
                            setActiveElection={this.setActiveElection}
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </section>
            <section  style={{backgroundColor: '#f0f7f9', minHeight: '60vh'}}>
              <div className='container'>
                {race}
              </div>
            </section>
          </div>
        <Footer />
      </div>
    )
  }
}

function select (state) {
  return {
    graph: state.graph,
    officesById: state.graph.officesById || {}
  }
}

export default {
    path: '/race/:officeId/:year',
    mainNav: false,
    component: connect(select)(reduxFalcor(RaceView)),
    exact: true
}
