import React from 'react'

function Loading () {
  return (
    <div style={{width: '100%', textAlign: 'center', marginTop: 15}} >
      <img style={{width: 40, margin: '0 auto'}} src='/img/loading.gif' alt={"Page is loading..."}/>
    </div>
  )
}

// Loading.propTypes = {
//   // children: PropTypes.element
// }

export default Loading
