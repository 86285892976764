
// --- Public Pages ------
import Home from 'pages/Home/Home';
import Race from 'pages/Race';
import CandidateView from 'pages/Candidate/CandidateNew';

import About from 'pages/About/About'
import FAQ from 'pages/About/FAQ'
import Terms from 'pages/About/Terms'

// --- Account pages --------
import Signup from 'pages/Auth/Signup/SignUp';
import Login from 'pages/Auth/Login/Login';
import Logout from 'pages/Auth/Login/Logout';
import ForgotPassword from 'pages/Auth/Login/ForgotPassword';
import CreateAccount from 'pages/Auth/Signup/CreateAccount';

// --- Candidate Auth Pages ----
import CandidateDashboard from 'pages/Auth/Candidate/Dashboard';
import CandidateCampaigns from 'pages/Auth/Candidate/Campaigns';
import CandidateCampaignEdit from 'pages/Auth/Candidate/CampaignEdit';
import CandidateCampaignPreview from 'pages/Auth/Candidate/CampaignPreview';
import CandidateCampaignStatus from 'pages/Auth/Candidate/CampaignStatus';


// --- Admin Auth Pages ---
import AdminDashboard from 'pages/Auth/Admin/Dashboard'
import AdminElectionManager from 'pages/Auth/Admin/ElectionManager'
import AdminSignupManager from 'pages/Auth/Admin/SignupManager'


import NoMatch from 'pages/404';

export default [
	// -- public
	...Home, 
	Race, 
	CandidateView,
	About,
	FAQ,
	Terms,
	
	// -- account -- 
	Signup,
	Login,
	Logout,
	...ForgotPassword,
	...CreateAccount,

	// -- candidate auth
	CandidateDashboard,
	CandidateCampaigns,
	CandidateCampaignEdit,
	CandidateCampaignPreview,
	CandidateCampaignStatus,
	
	// -- admin auth

	AdminDashboard,
	AdminElectionManager,
	AdminSignupManager,

	// -- util
	NoMatch
];

