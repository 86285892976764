import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import Candidate from 'components/race/Candidate'
import Loading from 'components/util/Loading'

import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'

import styled from 'styled-components'

let SidebarContainer = styled.div`
  flex-basis: 275px;
  padding: 20px;
  padding-top: 40px;
  padding-left: 0px;
  @media (max-width: 1450px) {
    display: none;
  }
`

let SidebarList = styled.ul`
  list-style:none;
  position: sticky; 
  top: 10px;
  border-left: 3px solid #999;
  padding-left: 10px;
  font-family: 'GT Pressura Mono', sans;
  li {
    padding: 5px 2px;
    line-height: 1;
    font-size: 0.95em;
    text-align: left;
    color: #999
  }
  li.office:hover {
      color: #000;
      cursor: pointer;
  }
  li.gov:hover {
    color:#999;
  }
`

class Race extends React.Component {

  constructor (props) {
    super()
    this.state={
      loading: true
    }
    this.getShallowProps = this.getShallowProps.bind(this)
  }

  fetchFalcorDeps (props) {
    props = props ? props : this.props
    this.setState({loading: true})
    return this.props.falcor.get(
      ['racesById', props.raceId, 'id'],
      ['racesById', props.raceId, 'election', ['name', 'id', 'type']],
      ['racesById', props.raceId, 'office', ['name', 'id']],
      ['racesById', props.raceId, 'candidates', 'length'],
    ).then((data) => {
      let numCandidates = get(data, `json.racesById[${props.raceId}].candidates.length`, 0)
      // console.log('trying', props.raceId, numCandidates)
      if(numCandidates) {
        return this.props.falcor.get(
          ['racesById', props.raceId, 'candidates', {from: 0, to: numCandidates - 1}, ['id', 'party']]
        ).then(()=> {
          this.setState({loading: false})
        })
      } else {
        return Promise.resolve({}).then(() => this.setState({loading: false}))
      }
    })
  }  

  componentWillReceiveProps (nextProps) {
    if(nextProps.raceId !== this.props.raceId) {
      //console.log('load new data',nextProps.raceId, this.props.raceId)
      this.fetchFalcorDeps(nextProps)
    }
  }

  getShallowProps (props) {
    let graph = props.graph
    let race = get(graph, `racesById[${props.raceId}]`, {})
    let raceId = get(race, 'id', null)
    let candidates = race.candidates ? race.candidates : {}
    let election = getRef(graph, race.election)
    
    
    let candidateData = Object.values(candidates)
      .filter(candidate => {
        //console.log(getRef(graph, candidate), candidate)
        return getRef(graph, candidate).id
      })
      .map(candidate => getRef(graph, candidate))
    
    let candidateList = candidateData.map(d => ({id:d.id}))

    let groups = []
   if(election.type === 'PRIMARY') {
      groups = candidateData.reduce((out,cand) => {
        let parties = cand.party ? cand.party.split(',') : []
        parties.forEach(party => {
          if(!out[`${party} Primary`]){
            out[`${party} Primary`] = []
          }
          out[`${party} Primary`].push(cand)
        })

        return out

      },{})
   } else {
      groups = {
        'General Election': candidateList
      }
   }


    return {
      groups,
      raceId,
      candidateList,
      candidateData,
      electionType: election.type
    }
  }

  renderCandidates () {
    if(this.state.loading) { return (<Loading />) }
    let props = this.getShallowProps(this.props)
    if (props.candidateList.length === 0) {
      return (
        <div>
          <h1 style={{fontSize: '4em'}}>Candidate Data Not Available</h1>
          <div style={{width: '100%', textAlign: 'center'}}>
            <button className='btn btn-primary'><h1 style={{fontSize: '2em', marginTop: 10}}>Submit Candidate</h1></button>
          </div>
        </div>
      )
    }

    console.log('renderCandidates', props.candidateData, props.groups)    
    return Object.keys(props.groups)
      .map((election,i) => (
         <ul className='grid' key={i}>
            <h4 style={{textAlign: 'right', width: '100%'}}>
              {election.toUpperCase()}
            </h4>
            {
              props.groups[election].map(cand => {
                console.log('race candidate',  cand.id)
                return (
                  <Candidate
                    raceId={this.props.raceId}
                    candidateId={cand.id} 
                    officeId={this.props.officeId} 
                    year={this.props.year} 
                  />
                )
              })
            }
          </ul>
      ))
  }

  render () {
    let props = this.getShallowProps(this.props)
    
    return (
      <div style={{display: 'flex', marginTop: '3em'}}>
          <SidebarContainer>
            <SidebarList >
            {
              Object.keys(props.groups)
                .map((election,i) => {
                  return (
                     
                     <div key={i} style={{marginBottom: 30}}>
                       <li key={i} style={{fontWeight: 600, fontSize: '0.85em'}}>
                        {election}
                       </li>
                        {
                         props.groups[election].map((cand,i)=> {
                            return (
                              <li className='office' key={i}>
                                {cand.name ? cand.name.toUpperCase() : ''}
                              </li>
                            )
                          })
                        }
                      </div>
                     
                  )
                })
            }
            </SidebarList>
          </SidebarContainer>
          <div className={'row '}  style={{flex: 1}}>
            <div className='col-sm-12' >
                {this.renderCandidates()}
            </div>
          </div>
      </div>
    )
  }
}

function select (state) {
  return {
    graph: state.graph
  }
}
export default connect(select)(reduxFalcor(Race))
