// import { host } from '../constants'
import { Model } from 'falcor'
import HttpDataSource from 'falcor-http-datasource'
import { AUTH_HOST, DEV_HOST } from 'config'

export const host = process.env.NODE_ENV === 'production' ? AUTH_HOST : DEV_HOST
//export const host = 'https://gtqy2ljxpa.execute-api.us-east-1.amazonaws.com/dev/'
  


class CustomSource extends HttpDataSource {
  onBeforeRequest(config) {
    if (localStorage) {
      const userToken = localStorage.getItem('userToken');
      config.headers['Authorization'] = userToken;
    }
  }
}

export const falcorGraph = (function () {
  var storedGraph = {} // graphFromCache() // {};//JSON.parse(localStorage.getItem('falcorCache'))
  let model = new Model({
    source: new CustomSource(host + '/graph', {
      crossDomain: true,
      withCredentials: false
    }),
    cache: storedGraph || {}
  }).batch()
  return model
})()

window.addEventListener('beforeunload', function (e) {
  var getCache = falcorGraph.getCache()
  console.log('windowUnload', getCache)
  localStorage.setItem('falcorCache', JSON.stringify(getCache))
})