import React from 'react'
import {Text, TextArea} from './Text'
import { ListData } from './List'
import { IFrame } from './IFrame'

import get from 'lodash.get'

const Components =  {
	Text,
	TextArea,
	ListData,
	IFrame
}

export default ({config, data, className}) => {
	return (
		<div className={className}>
	    {
	      config.map(item => {
	        let itemData = get(data,`${item.name}`, [])
	        if (item.transform) {
	        	itemData = item.transform(itemData)
	        }
	        let Display =  Components[item.component] || Text 
	        // console.log('data', itemData, item.name, itemData.length)
	        if((itemData.length === 0) || itemData === '') {
	        	return <div />
	        }
	        return (
	          	<div className={item.containerClass}>
		          	<div>
			            {item.title ? (
			            <h3 className={item.titleClass}>
			              {item.title}
			            </h3>) : ''}
		            	<Display {...item.props} data={itemData} />
		          	</div>
	        	</div>
	        )
	      })
	    }
	  	</div>
	)
}