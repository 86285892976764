import React from 'react';
import { Route, Redirect } from "react-router-dom";

// Layout Components
import LoadingPage from "components/loading/LoadingPage"

const DefaultLayout = ({component: Component, ...rest}) => {

  if ( rest.isAuthenticating && rest.authLevel >= 0  ) {
    return (
      <Route {...rest} render={matchProps => (
        <div style={{background:"#f0f7f9"}}> 
          <LoadingPage />
        </div>
      )} />
    )
  }

  return sendToLgin(rest) ?
  (
    <Redirect
      to={{
        pathname: rest.authLevel !== undefined ? '/' : "/login",
        state: { from: rest.router.location }
      }}
    />
  ) : (
    <Route {...rest} render={matchProps => (<Component {...matchProps} {...rest}/>)} />
  )
}

function sendToLgin (props) {
  const requiredAuthLevel = props.authLevel !== undefined ? props.authLevel : props.auth ? 1 : -1;
  //console.log("<Layout.sendToLogin>", requiredAuthLevel, props.user.authLevel);
  return props.user.authLevel < requiredAuthLevel;
}

export default DefaultLayout
