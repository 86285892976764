import React from "react";
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import get from 'lodash.get'

import Footer from 'components/util/Footer'
import KYCNav from 'components/util/KYCNav'
import CampaignView from 'components/campaign/CampaignView'

class CandidateView extends React.Component {
 

  fetchFalcorDeps () {
    
    const params = this.props.match.params
    return this.props.falcor.get(
      ['personsById', params.candidateId, 'campaign', params.year, 'office', params.officeId, ['id', 'campaign_data']],
    )
  }

  render() {
    // let parts = this.renderParts(data.candidate)
    const params = this.props.match.params
    console.log('<CandidateViewNew>', this.props.campaignId)
    return (
      <div>
       <div className='bg-custom' style={{minHeight: '100vh'}}>
         <KYCNav />
          <div className='max-w-5xl mx-auto'>
          <CampaignView 
            campaignId={this.props.campaignId} 
            candidateId={params.candidateId} 
            year={params.year} 
            officeId={params.officeId}
          />
          </div>
        <Footer />
        </div>
      </div>
    )
  }
}

function select (state, props) {
  var params = props.match.params
  //console.log('new', state.graph)
  return {
    //user: state.user.toJS(),
    officesById: state.graph.officesById || {},
    personsById: state.graph.personsById || {},
    campaignId: get(state.graph, `personsById[${params.candidateId}].campaign[${params.year}].office[${params.officeId}].id`, null)
  }
}



export default
{
    path: '/race/:officeId/:year/:candidateId',
    mainNav: false,
    component: connect(select)(reduxFalcor(CandidateView))

}


