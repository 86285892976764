import React from 'react'
import { Dropdown } from 'semantic-ui-react'

import 'styles/semantic-ui.css'

import states from './states'

const stateOptions = Object.keys(states).map(abbr => {
  return {
    key: abbr, value: abbr, text: states[abbr]
  }
}) 
const noop = () => {}

const StateSelector = ({value, onChange=noop}) => (
  <Dropdown
    placeholder='Select State'
    fluid
    search
    selection
    id='office_state'
    name={'office_state'}
    onChange={onChange}
    options={stateOptions}
    value={value}
    className='py-4'
  />
)

export default StateSelector