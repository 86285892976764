import React,{ Component } from 'react';

import Layout from 'pages/Auth/components/Layout'

import ElectionTable from './components/ElectionsTable'
import StateSelector from 'pages/Auth/Candidate/components/campaign/components/StateSelector'
import { Dropdown } from 'semantic-ui-react'

import get from 'lodash.get'



class ElectionManager extends Component {
  state = {
      year: 2020,
      office_state:  'NY',
      activeOrg: 'United States Congress',
      isLoading: false,
      orgs: [],
      options: []
  }

  handleChangeOffice = (e, {name, value}) => this.setState({[name]: value})
  
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  setOrgs = orgs => {
  	this.setState({orgs, activeOrg: get(orgs ,'[0].value', '')})
  }

  render () {
  	return (
		  <Layout>
		    <div>
				  <div className="md:grid md:grid-cols-4 md:gap-6">
				    <div className="md:col-span-1">
				      <div className="px-4 sm:px-0 sticky top-16">
				        <h3 className="text-lg font-medium leading-6 text-gray-900">Election Manager
				        </h3>
				        <p className="mt-1 text-sm leading-5 text-gray-600">
				          Filters
				        </p>
				        <div>
				        	<StateSelector
                  	onChange={this.handleChangeOffice} 
                  	value={this.state.office_state}
                	/>
				        </div>
				        <div>
				        	<Dropdown
								    placeholder='Select Organization'
								    fluid
								    search
								    selection
								    id='activeOrg'
								    name={'activeOrg'}
								    onChange={this.handleChangeOffice}
								    options={this.state.orgs}
								    value={this.state.activeOrg}
								    className='py-4'
								  />
				        </div>
				        <div>
				        	<Dropdown
								    placeholder='Select Year'
								    fluid
								    search
								    selection
								    id='year'
								    name={'year'}
								    onChange={this.handleChangeOffice}
								    options={[2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012]
								    	.map(d => { return {key: d, value: d, text: d}})}
								    value={this.state.year}
								    className='py-4'
								  />
				        </div>
				      </div>
				    </div>
				    <div className=" sm:mt-0 md:col-span-3">
				    	<div className="shadow-md sm:rounded-md">
				         <div className="px-4 py-5 bg-white sm:p-6">
				     	 		<ElectionTable 
				     	 			state={this.state.office_state} 
				     	 			year={this.state.year} 
				     	 			setOrgs={this.setOrgs}
				     	 			activeOrg={this.state.activeOrg}
				     	 		/>
				     	  </div>
				     	</div>
				    </div>
				  </div>
				</div>
		  </Layout>
		)
	}
}

export default {
  name: 'Election Manager',
  path: '/admin/elections',
  mainNav: false,
  authed: true,
  authLevel: 5,
  component: ElectionManager
}