import React from "react"


export const Text = ({data, transform, ...rest}) => (
  <p {...rest}>
    {typeof data === 'object' ? JSON.stringify(data) : data}
  </p>
)

export const TextArea = ({data, transform, ...rest}) => (
  <p {...rest}>
    {typeof data === 'object' ? JSON.stringify(data) : data.split('\n').map(d => <p>{d}</p>)}
  </p>
)


