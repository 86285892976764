import React from "react";
// import { Link } from "react-router-dom";

export default ({ title, text, dismiss, color='red' }) => (
  <div className={`rounded-md bg-${color}-50 p-4`}>
    <div className={`flex`}>
      <div className={`flex-shrink-0`}>
        <svg className={`h-5 w-5 text-${color}-400`} fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
        </svg>
      </div>
      <div className={`ml-3`}>
        <h3 className={`text-sm leading-5 font-medium text-${color}-800`}>
          {title}
        </h3>
        {text ? <p>{text}</p> : ''}
      </div>
      {dismiss ? 
        <div className={`ml-auto pl-3`}>
          <div className={`-mx-1.5 -my-1.5`}>
            <button 
              onClick={dismiss}
              className={`inline-flex rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:bg-${color}-100 transition ease-in-out duration-150`}>
              <svg className={`h-5 w-5`} viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
              </svg>
            </button>
          </div>
        </div> : ''
      }
    </div>
  </div>
)

