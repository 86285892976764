import React from "react";
import { NavLink } from "react-router-dom";
// import { classNames } from "../utils";
import { Icon } from "../HeroIcons";

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default ({ active, to, icon, className, children }) => {
  /*let linkClasses = classNames(
    className,
    "group flex items-center px-2 py-1  text-sm leading-6 font-medium transition ease-in-out duration-150",
    active && "text-blue-700 rounded-md hover:bg-white focus:outline-none focus:bg-gray-200",
    !active && "text-gray-500 rounded-md hover:text-gray-600 hover:bg-blue-200 focus:outline-none focus:text-gray-900 focus:bg-gray-100"
  );*/
   

  let linkClassName = ' group flex items-center px-2 py-1 text-sm leading-6 font-medium transition ease-in-out duration-150 text-gray-500 rounded-md hover:text-gray-600 hover:bg-blue-200 focus:outline-none focus:text-gray-900 focus:bg-gray-100'
  let activeClassName = ' group flex items-center px-2 py-1 text-sm leading-6 font-medium transition ease-in-out duration-150 text-blue-700 rounded-md  focus:outline-none focus:bg-gray-200'
 
  return (
    <NavLink to={to} className={linkClassName} activeClassName={activeClassName}>
      <Icon icon={icon} className={'mr-4 h-6 w-6 transition ease-in-out duration-150'} />
      {children}
    </NavLink>
  );
  

};