import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import CandidateAdminRow from './CandidateAdminRow'
import CandidateNew from './CandidateNew'

import Modal from 'layouts/components/Modal'

import get from 'lodash.get'

import getRef from 'utils/falcorGetRef'


class RaceAdminRow extends React.Component {

 constructor() {
    super();
    this.state = {
      modal: false
    }
  }

  toggleModal = () => {
    this.setState({modal: !this.state.modal})
  }

  fetchFalcorDeps () {
    let props = this.props
    this.setState({loading: true})
    return this.props.falcor.get(
      ['racesById', props.raceId, 'election', ['id','name', 'type', 'election_date']],
      ['racesById', props.raceId, 'candidates', 'length']
    ).then(data => {
      const numCandidates = get(data, `json.racesById[${props.raceId}]candidates.length`, 0)
      if(numCandidates === 0) { 
        return Promise.resolve({})
      }
      return this.props.falcor.get(
        ['racesById', props.raceId, 'candidates', {from: 0, to: numCandidates -1}, ['id','name', 'party']]
      )

    })
  }

  render () {
    return (
      <>
        <tr className='bg-gray-100'>
           <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200" >
              {this.props.electionType} {this.props.electionDate} {this.props.raceId}
           </td>
           <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200" colSpan='1'>
              {this.props.numCandidates} Candidates
           </td>
           <td className="py-2 px-2 whitespace-no-wrap border-b border-gray-200 text-right" colSpan="2">
            {this.props.electionType === 'PRIMARY' ? 
              (<span className="inline-flex rounded-md shadow-sm" colSpan='1'>
                <Modal open={this.state.modal} toggle={this.toggleModal}>
                  <CandidateNew 
                    raceId={this.props.raceId}
                    toggle={this.toggleModal}/>
                </Modal>
                <button onClick={this.toggleModal} type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                  Add Candidate
                </button>
              </span>) : ''}
          </td>
        </tr>
        {this.props.candidates.map((d,i) => <CandidateAdminRow 
            type={this.props.electionType} 
            candidateId={d.id} 
            key={i} 
            raceId={this.props.raceId} 
            officeId={this.props.officeId} 
            year={this.props.year} 
          />)}
      </>
    )
  }
}

function select (state, props) {
  const graph = state.graph
  const election = getRef(graph, get(graph, `racesById[${props.raceId}].election`, {}))
  const electionDate = get(election, 'election_date.value', false )
  const numCandidates = get(graph, `racesById[${props.raceId}].candidates.length`, 0)
  let candidates = []
  for(let i = 0; i < numCandidates; i++) {
    candidates.push(getRef(graph, get(graph, `racesById[${props.raceId}].candidates[${i}]`, {})))
  }




  return {
    electionType: election.type,
    electionDate: electionDate ? new Date(electionDate).toLocaleString().split(',')[0] : '',
    numCandidates,
    candidates
  }
}

export default connect(select)(reduxFalcor(RaceAdminRow))


