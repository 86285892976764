import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
// import Race from 'components/race/Race'
import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'
import './RaceElections.css'
// import GovernmentElection from 'components/election/Election'

// import compareShallowProps from 'redux/utils/compareShallowProps'

class OpenCivicView extends React.Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      showChild: null,
      activeElection: null
    }
    this.shallowProps = this.shallowProps.bind(this)
    this.setActiveElection = this.setActiveElection.bind(this)
  }

  fetchFalcorDeps (props) {
    props = props ? props : this.props
    return this.props.falcor.get(
      ['officesById', props.officeId, 'races', props.year, [{from: 0, to: 2}], ['id', 'election'], ['id','name', 'type', 'election_date']]
    )
    .then(data => {
      this.setState({loading: false})
      return data
    })
  }

  componentWillMount () {
    var currentElections = this.shallowProps(this.props).elections
    if(Object.keys(currentElections).length > 0 && !this.state.activeElection) {

      this.props.setActiveElection(currentElections[Object.keys(currentElections)[0]].id)
    }
  }

  componentWillReceiveProps(nextProps) {
    var currentElections = this.shallowProps(this.props).elections
    var nextElections = this.shallowProps(nextProps).elections
    
    if (Object.keys(currentElections).length !== Object.keys(nextElections).length  
        || (!this.state.activeElection && Object.values(nextElections)[0] ))
    {
     
      /* -----------
        We assume 0 is primary and 1 is general.
        If its passed the primary date we set to the general
        othewise we show the primary
      ------------------ */
      let activeElection =  new Date() > new Date(Object.values(nextElections)[0].election_data.election_date.value)
        ? Object.values(nextElections)[1].id
        :  Object.values(nextElections)[0].id
      this.setState({ activeElection })
      if (this.props.setActiveElection) {
        this.props.setActiveElection( activeElection )
      }
    }

  }
  
  setActiveElection (e) {
    e.preventDefault()
    if (this.props.setActiveElection) {
      this.props.setActiveElection(e.target.getAttribute('name'))
    }
    this.setState({activeElection: e.target.getAttribute('name')})

  }

  shallowProps (props) {
    const year = props.year
    const graph = props.graph
    let officeRaces =  get(graph, `officesById[${props.officeId}].races[${year}]`, {})
    let elections = Object.keys(officeRaces)
      .filter(key => key !== 'length' && officeRaces[key])
      .reduce((output, raceKey) => {

        let race = getRef(graph,officeRaces[raceKey])
        let election =  getRef(graph,race.election)
        race.election_data = election
        race.date = new Date(election.election_date).toLocaleString()
        output[raceKey] = race
        
        return output
      },{})

    return {
      elections,
      year
    }
  }

  render () {
    let props = this.shallowProps(this.props)
    //let numElection = Object.keys(props.elections).length
    let elections = Object.keys(props.elections).map(raceId => {
      return (
        <li 
          key={raceId} 
          
        >
          <h3>
            <div
              className={+props.elections[raceId].id === +this.state.activeElection ? 'border-b-4 border-blue-600' : 'pb-1'} 
              onClick={this.setActiveElection} name={props.elections[raceId].id}>
              {props.elections[raceId].election_data.name}
            </div>
            <div className='pt-1' style={{fontSize: '0.5em', fontWeight: '300', fontColor: '#ccc'}}>{props.elections[raceId].election_data.date}</div>
          </h3>
        </li>
      )
    })

    return (
      <ul className='ElectionNav'  style={{display: 'flex', justifyContent: 'center', width: '100%'}}>{elections}</ul>
    )
  }
}

function select (state) {
  return {
    officesById: state.graph.officesById ? state.graph.officesById : {},
    graph: state.graph
  }
}

export default connect(select)(reduxFalcor(OpenCivicView))
