import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import { Link } from 'react-router-dom'

import get from 'lodash.get'

// import getRef from 'utils/falcorGetRef'


const Menu = (props) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const toggleMenu = selectedValue => {
    setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const general = () => {
    props.addToGeneral()
    setOpen(!open)
  }

  const remove = () => {
    props.remove()
    setOpen(!open)
  }

  return (
      <div className="relative inline-block text-left">
      <div>
        <span className="">
          <button onClick={toggleMenu} type="button" className="transition ease-in-out duration-150 outline-none" id="options-menu" aria-haspopup="true" aria-expanded="true">
            <svg className={`h-5 w-5 text-gray-400`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
              </svg>
          </button>
        </span>
      </div>
      <div ref={node} className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg ${open ? '' : 'hidden'}`}>
        <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          
          <div className="border-t border-gray-100"></div>
          <div className="py-1">
            
            <Link to={`/race/${props.officeId}/${props.year}/${props.candidateId}`} className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>
              </svg>
              View
            </Link>
          </div>
          { props.type === 'PRIMARY' ? 
          (
          <React.Fragment>
          <div className="border-t border-gray-100"></div>
          <div className="py-1 cursor-pointer" onClick={general}>
            <div  className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd"/>
              </svg>
              Add To General
            </div>
          </div></React.Fragment>) : ''
          }
          <div className="border-t border-gray-100"></div>
          <div className="py-1 cursor-pointer" onClick={remove}>
            <div  className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
              <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"/>
              </svg>
              Remove From Race
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  
}


class RaceAdminRow extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      menu: false,
      activeElection: null
    }
  }

  toggleMenu = () => {
    this.setState({menu: !this.state.menu})
  }

  fetchFalcorDeps () {
    this.setState({loading: true})
    if(!this.props.candidateId) {
      return Promise.resolve({})
    }
    return this.props.falcor.get(
      ['personsById', this.props.candidateId, ['name', 'address', 'party', 'phones', 'urls', 'photoUrl', 'channels', 'id', 'races']], 
      ['personsById', this.props.candidateId, 'campaign', this.props.year, 'office', this.props.officeId, ['id', 'payment']]
    )
    // .then(data => {
    //   console.log('candidate row data', data)
    // })
  }

  addToGeneral = () => {
      let args = {
        id: this.props.candidateId,
        races: [ ...this.props.races, (this.props.raceId+1)],
        allRaces: (this.props.raceId+1)
      }
    this.setState({loading: true})
    return this.props.falcor.call(['personsById','updateRaces'], [args], [], [])
      .then(response => {
        //console.log('campain created', response)
      })
  }

  remove = () => {
      let args = {
        id: this.props.candidateId,
        races: this.props.races.filter(d => d !== this.props.raceId),
        allRaces: this.props.raceId
      }
    this.setState({loading: true})
    return this.props.falcor.call(['personsById','updateRaces'], [args], [], [])
      .then(response => {
        console.log('campain created', response)
      })
  }

  removefromRace = () => {

  }

  render () {
    const props = this.props
    return (
     <tr className='hover:bg-blue-100'>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div className="flex items-center">
            <span className="inline-block h-10 w-10 border-2 border-gray-800 rounded-full overflow-hidden bg-blue-100 bg-cover" style={{backgroundImage: `url(${get(this.props,`photoUrl`, '')})`}}>                
                {get(this.props,`photoUrl`, false) ?  '' :
                (<svg className="h-full w-full text-blue-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>)
                }
              </span>
            <div className="ml-4">
              <div className="text-sm leading-5 font-medium text-gray-900">{props.name}</div>
              <div className="text-sm leading-5 text-gray-500">{props.party}</div>
            </div>
          </div>
        </td>
        
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div className="text-sm leading-5 text-gray-900 text-center">Claimed</div>
          <div className="text-sm leading-5 text-gray-500 text-center">
            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${this.props.claimed ? 'green-100' : 'red-100'} text-${this.props.claimed ? 'green-800' : 'red-800'}`}>
              {this.props.claimed ? '✓' : '✘' }
            </span>
          </div>
        </td>
        
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div className="text-sm leading-5 text-gray-900 text-center">Activated</div>
          <div className="text-sm leading-5 text-gray-500 text-center">
            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${this.props.activated ? 'green-100' : 'red-100'} text-${this.props.activated ? 'green-800' : 'red-800'}`}>
              {this.props.activated ? '✓' : '✘' }
            </span>
          </div>
        </td>

        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
          <div>
              <Menu 
                type={this.props.type}
                addToGeneral={this.addToGeneral}
                remove={this.remove}
                officeId={this.props.officeId}
                candidateId={this.props.candidateId}
                year={this.props.year}
              />
          </div>
        </td>
      </tr>
    )
  }
}

function select (state, props) {
  const graph = state.graph
  const candidate =  get(graph, `personsById[${props.candidateId}]`, {})
  const claimed = get(graph, `personsById[${props.candidateId}].campaign[${props.year}].office[${props.officeId}].id`, false)
  const activated = get(graph, `personsById[${props.candidateId}].campaign[${props.year}].office[${props.officeId}].payment.value`, false)
  const races =  get(candidate, 'races.value', [])
  
  return {
    name: candidate.name,
    party: candidate.party,
    photoUrl: candidate.photoUrl,
    claimed,
    activated,
    races

    
  }
}

export default connect(select)(reduxFalcor(RaceAdminRow))


