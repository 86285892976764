import React from 'react'
import { Link } from 'react-router-dom'

function Footer (props) {
  return (
    <div className="foot">
      <div className='container' style={{height: '100%'}}>
        <div className='row'>
          <div className='col-12 text-center'>
            <h1 className='theme-font' style={{fontSize: '3em', paddingTop: 65, color: '#efefef'}}>
              RUNNING FOR OFFICE?
              &nbsp;<Link to='/candidate/signup' className='text-blue-800'>SIGN UP.</Link>
            </h1>
          </div>
        </div>
        
        <nav>
          <ul className='main-nav' style={{marginBottom: 10, paddingBottom: 0, right: 10, zIndex: 99}}>
            <li><Link to='/login'>LOGIN</Link></li>
            <li>
              <Link to='/' style={{textDecoration: 'none', backgroundColor: '#333', borderRadius: '50%', fontSize: '1.5em', color: '#0095ff'}}>
                <i className="icon-social-facebook"></i>
              </Link>
            </li>
            <li> 
              <Link to='/' style={{textDecoration: 'none', backgroundColor: '#333', borderRadius: '50%',fontSize: '1.5em',  color: '#0095ff'}}>
                <i className="icon-social-twitter"></i>
              </Link>
            </li>
          </ul>
          <ul className='main-nav text-center sm:text-left' style={{marginBottom: 10, paddingBottom: 0}}>
            <li><Link to='/about'>ABOUT</Link></li>
            <li><Link to='/faq'>FAQ</Link></li>
            {/*<li><Link to='/'>PRIVACY</Link></li>*/}
            <li><Link to='/terms'>TERMS</Link></li>
            <li><span style={{color: '#333'}}>&copy;2017 Know Your Candidate v0.1.14</span></li>
          </ul>
        </nav>
        
      </div>
    </div>
  )
}

export default Footer
 