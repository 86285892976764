import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import ResponsiveMap from 'components/util/map/ResponsiveMap'
import RaceAdminRow from './RaceAdminRow'
/*import RaceElections from 'components/election/RaceElections'
import Race from 'components/race/Race'
*/
import get from 'lodash.get'

import getRef from 'utils/falcorGetRef'


class OfficeAdminRow extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      activeElection: null
    }
    this.setActiveElection = this.setActiveElection.bind(this)
    this.setActive = this.setActive.bind(this)
  }

  fetchFalcorDeps () {
    let props = this.props
    // console.log('fetchFalcorDeps', props.officeId, props.year)
    this.setState({loading: true})
    return this.props.falcor.get(
      ['officesById', props.officeId, 'races', props.year,  {from: 0, to: 1}, 'id'],
      ['officesById', props.officeId, 'division', ['geojson']],
      ['officesById', props.officeId, 'government', ['name', 'id']],
      ['officesById', props.officeId, 'races', props.year, {from: 0, to: 1}, 'candidates', 'length']
    ).then(data => {
      this.setState({loading: false})
    })
  }

  shallowProps (props) {
    const officeId = props.officeId
    const graph = props.graph
    var office = get(graph, `officesById[${officeId}]`, {})
    var officeDivision = getRef(graph, office.division)
    var theGeo = officeDivision.geojson ? officeDivision.geojson.value : null
    let raceIds = [
      get(getRef(graph,get(office,`races[${props.year}][0]`, {})),'id',null),
      get(getRef(graph,get(office,`races[${props.year}][1]`, {})),'id',null)
    ].filter(d => d)

    
    
    // console.log('primary', primaryRaces, graph)


    return {
      officeGeo: theGeo,
      raceIds
    }
  }

  setActiveElection (electionId) {
    this.setState({activeElection: electionId}) 
  }

  setActive () {
    this.props.activate(this.props.officeId)
  }

  renderRace () {

  }


  render () {
    var data = this.shallowProps(this.props)
    // var race = this.state.activeElection
    // ? <Race
    //     raceId={this.state.activeElection}
    //     officeId={data.officeId}
    //     year={data.year}
    //   />
    // : <span />

    return (
      <>
      <tr className='hover:bg-blue-100 cursor-pointer' onClick={this.setActive}>
        <td className="px-3 py-4 whitespace-no-wrap border-b border-gray-200 max-w-sm">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10 bg-blue-500 rounded-full overflow-hidden bg-cover pt-2">
              <span className="h-full w-full rounded-full overflow-hidden" >
               <ResponsiveMap
                  mapDiv={'officeMap' + this.props.officeId}
                  geo={data.officeGeo}
                />
              </span>
            </div>
            <div className="ml-4 ">
              <div className="text-sm leading-5 font-medium text-gray-900">{this.props.officeName}</div>
              <div className="text-xs leading-5 text-gray-500">{this.props.divisionName}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${this.props.payment === 'Inactive' ? 'red' : 'green'}-100 text-${this.props.payment === 'Inactive' ? 'red' : 'green'}-800`}>
          </span>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
          <div>
              <svg className={`h-5 w-5 text-gray-400 ${this.props.activeOffice === this.props.officeId ? 'transform rotate-90' : ''}`} viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
              </svg>
          </div>
        </td>
      </tr>
      {this.props.activeOffice === this.props.officeId ? 
        (
          data.raceIds.map(d => <RaceAdminRow 
            raceId={d} 
            raceIds={data.raceIds}
            officeId={this.props.officeId}
            key={d}
            year={this.props.year} 
          />)
        )
        : <></>
      }
      </>
      
    )
  }
}

function select (state) {
  return {
    graph: state.graph,
    officesById: state.graph.officesById || {}
  }
}

export default connect(select)(reduxFalcor(OfficeAdminRow))


/*<div>
        <div style={{backgroundColor: '#f0f7f9', minHeight:'100vh'}}>
          <KYCNav />
            <section>
              <div className='container'>
                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '5em',    justifyContent: 'center'}}>
                  <div style={{flexBasis: 200,}}>
                      <div style={{  paddingTop: 40, 'border': '2px solid black', backgroundColor: '#005bcc',width: 200, height:200,  borderRadius: '50%'}}>
                      
                      </div>
                  </div>
                  <div style={{flex: 1, zIndex: 99}}>
                      <div style={{paddingTop: 10}}>
                        <h1 style={{fontSize:'5em', lineHeight: 0.78}}>{this.props.match.params.year} {data.officeName} Race</h1>
                        <h2 style={{textAlign: 'center', fontFamily: "GT Pressura Mono", color: '#6e6e6e', lineHeight: 0.8, fontSize: '1.5em'}}>
                          {data.divisionName.toUpperCase()}
                          </h2>
                          <h2 style={{textAlign: 'center', fontFamily: "GT Pressura Mono", color: '#6e6e6e', lineHeight: 0.8, fontSize: '1.5em'}}>
                           {data.officeOrg.toUpperCase()}
                          </h2>
                        <div style={{paddingTop: 25}}>
                          <RaceElections 
                            officeId={this.props.match.params.officeId} 
                            year={this.props.match.params.year} 
                            setActiveElection={this.setActiveElection}
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </section>
            <section  style={{backgroundColor: '#f0f7f9', minHeight: '60vh'}}>
              <div className='container'>
                {race}
              </div>
            </section>
          </div>
        <Footer />
      </div> */