import React, { Component } from 'react';
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import { Link } from 'react-router-dom'

import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'

import LoadingPage from "components/loading/LoadingPage"
import DisplayFactory from 'layouts/components/Display'
import { EndorsementListItem, PressListItem } from 'layouts/components/Display/List'

let LayoutConfig = [
  {
    title: 'Pitch',
    name: 'pitch',
    titleClass: 'text-2xl text-gray-800 tracking-tight',
    containerClass: 'py-2 sm:rounded-sm sm:p-6 col-span-6',
    component: 'Text',
    props: {
      className: 'py-2 text-2xl text-gray-400 text-bold'
    }
  },
  {
    title: 'Top Issues',
    name: 'issues',
    component: 'Text',
    titleClass: 'text-xl text-gray-800 tracking-tight',
    containerClass: 'py-2 sm:rounded-sm sm:p-6 col-span-6',
    props: {
      className: 'py-2 text-2xl text-gray-500'
    },
    transform: (d) => d.join ? d.join(', ') : d
  },
  {
    title: 'Platform',
    name: 'platform',
    component: 'TextArea',
    titleClass: 'text-xl text-blue-700 tracking-tight',
    containerClass: 'py-2 sm:rounded-sm sm:p-6 col-span-6',
    props: {
      className: 'py-2 text-lg text-gray-500'
    }
  },
  {
    title: 'Endorsements',
    name: 'endorsements',
    titleClass: 'text-xl text-blue-700 tracking-tight',
    component: 'ListData',
    props: {
      ListItem: EndorsementListItem,
      
    },
    containerClass: 'py-2 sm:rounded-sm sm:p-6 col-span-6'
  },
  {
    title: 'Video',
    name: 'video',
    titleClass: 'text-xl text-blue-700 tracking-tight',
    component: 'IFrame',
    containerClass: 'py-2 sm:rounded-sm sm:p-6 col-span-6 sm:col-span-3'
  },
  {
    title: 'Press',
    name: 'press',
    titleClass: 'text-xl text-blue-700 tracking-tight',
    component: 'ListData',
    props: {
      ListItem: PressListItem
    },
    containerClass: 'py-2 sm:rounded-sm sm:p-6 col-span-6 sm:col-span-3'
  }
  
]

class CampaignEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    }
  }
  
  fetchFalcorDeps () {
    console.log('<campaignView 1 >', this.props.campaignId, this.props)
    if(this.props.campaignId) { 
      console.log('<Normal Get>')
      return this.props.falcor.get(
        ['campaignsById', this.props.campaignId, ['id', 'campaign_data', 'year']],
        ['campaignsById', this.props.campaignId, 'person', ['id','name', 'party']],
        ['campaignsById', this.props.campaignId, 'office', ['name','id']],
        ['campaignsById', this.props.campaignId, 'office', 'division', ['name','ocdId']]
      )
      .then(r => { this.setState({loading:false})}) 
    } else if(this.props.candidateId && this.props.year && this.props.officeId) {
      console.log('<campaignView> alt get')
      return this.props.falcor.get(
        ['personsById', this.props.candidateId, ['name', 'address', 'party', 'phones', 'urls', 'photoUrl', 'channels', 'id']],
        ['officesById', this.props.officeId,
          ['name', 'level', 'term', 'lastElectionYear', 'id']],
        ['officesById', this.props.officeId, 'organization',['name', 'id']],
        ['officesById', this.props.officeId, 'division', ['ocdId', 'name']],
        ['officesById', this.props.officeId, 'government', ['name', 'id']],
        ['personsById', this.props.candidateId, 'campaign', this.props.year, 'office', this.props.officeId, 'campaign_data']
        )
        .then(r => { this.setState({loading:false})}) 
    }
  }

  componentDidUpdate(prevProps) {
    console.log('update', prevProps.campaignId, )
    if(prevProps.campaignId !== this.props.campaignId) {
      this.fetchFalcorDeps()
    }
  }

  render () {
    if(this.state.loading) {
      return (<LoadingPage width={'100%'} height={'80vh'}/>)
    }


    return (
      <div className={'min-h-screen'}>
        <div className="flex items-center ml-2">
          <div className="flex-shrink-0">
            <button  className="flex-shrink-0 max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
              <span className="inline-block h-32 w-32 md:h-48 md:w-48 border-2 border-gray-800 rounded-full overflow-hidden bg-blue-100 bg-cover" style={{backgroundImage: `url(${get(this.props,`campaign_data.photo`, '')})`}}>                
                {get(this.props,`campaign_data.photo`, false) ?  '' :
                (<svg className="h-full w-full text-blue-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>)
                }
              </span>
            </button>
          </div>
          <div className="ml-4 pt-7">          
            <div className="text-6xl md:text-10xl leading-5 font-medium text-gray-900 inline" style={{fontFamily: 'TIMMONS NY', textTransform:'uppercase'}}>
              {this.props.name}
            </div>
            <div className="text-sm leading-none md:text-2xl text-blue-700"> for {this.props.office} 
              <div className='hidden md:block text-gray-700'>{titleCase(this.props.division)}</div>
              </div>
          </div>
        </div>
        <div className='border-t-2 border-r-2 border-l-2 border-gray-800 py-6 px-10 -mt-8'/>
        <div className='border-l-2 border-gray-800 py-32 px-10 '/>
        <div className="max-w-7xl mx-auto -mt-64">
          <div className='lg:px-12 px-6'>
            {
              this.props.campaignId ? 
              <DisplayFactory
                className='grid grid-cols-6 gap-6'
                config={LayoutConfig}
                data={this.props.campaign_data}
              /> : <div>
                <h4 className='mx-auto theme-font text-center text-5xl pt-12 '>Candidate not signed up.</h4>
                  <Link className="flex items-center justify-center px-5 max-w-lg mx-auto py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" 
                  to={`/candidate/create/${ocdGetState(get(this.props,'divisionId', ''))}/${this.props.officeId}/${this.props.person}`}>
                      Claim this Profile
                    </Link>
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state,p) => {
let graph = state.graph
  
  let campaignId = p.campaignId
  let campaign =  get(graph, `campaignsById[${campaignId}]`,{})
  let person = p.campaignId ? getRef(graph, campaign.person) : get(graph, `personsById[${p.candidateId}]`, {})
  let office = p.campaignId ? getRef(graph, campaign.office) : get(graph, `officesById[${p.officeId}]`, {})
  let division = getRef(graph, office.division)
  let campaign_data = campaign.campaign_data ? campaign.campaign_data.value : {}
  console.log('<campaignView 2>', campaign_data, person, office, graph)

  return {
    campaignId,
    campaign_data,
    id: campaign.id,
    name: person.name,
    person: person.id,
    party: person.party,
    year: campaign.year ? campaign.year : p.year,
    office: office.name,
    officeId: p.officeId ? p.officeId : office.id,
    division: get(division, 'name', ''),
    divisionId: get(division, 'ocdId', '')
  };
};
  

const ocdGetState = (ocdId='') => {
    console.log('ocdId', ocdId)
    let state =  ocdId.split('/')[2]

    return state ? state.split(':')[1].toUpperCase() : ''
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(CampaignEdit))

function titleCase(str) {
   var splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   // Directly return the joined string
   return splitStr.join(' '); 
}