import React from 'react';
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import get from 'lodash.get'

import 'styles/tailwind.css';
import Row from "./CampaignTableRow"
import LoadingPage from "components/loading/LoadingPage"




class CampaignTable extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true
    }
  }

  toggleModal = () => {
  	this.setState({modal: !this.state.modal})
  }
  
  fetchFalcorDeps () {
  	return this.props.falcor.get(['campaigns', 'length'])
  		.then(res => {
  			if(res.json.campaigns.length === 0){
          this.setState({loading:false})
  				return Promise.resolve({})
  			}
  			return this.props.falcor.get(['campaigns','byIndex',{from: 0, to: res.json.campaigns.length -1}, ['id']])
  				.then(r => {
  					this.setState({loading:false})
  				})
  		})
  }

  renderNoData () {
      return (
         <div className="bg-gray-50">
            {/*<div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Show voters what you stand for.
                <br />
                <span className="text-indigo-600">Create your first campaign.</span>
              </h2>
              <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0 pr-20">
                <div className="inline-flex rounded-md shadow">
                  <button onClick={this.props.toggle} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Get started
                  </button>
                </div>
              </div>
            </div>*/}
          </div>
      )

  }

  render() {
    if(this.state.loading) {
      return <LoadingPage height={150} width={'100%'}/>
    }

    if(this.props.numCampaigns === 0){
      return this.renderNoData()
    }

    let rows = Array(this.props.numCampaigns).fill(0);
    return(
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Candidate
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Office
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Election Year
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50" />
                </tr>
              </thead>
              <tbody className="bg-white">
                {
                  rows.map((d,i) => {
                    console.log('')
                    return <Row key={i} index={i} />
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    numCampaigns: get(state, `graph.campaigns.length`, 0)

  };
};

export default  connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(CampaignTable))