import React from 'react';

//import get from 'lodash.get'

import AvlMap from "components/AvlMap"

import DivisionsLayer from "./layers/DivisionsLayer"



class NationalLanding extends React.Component {
  
  DivisionLayer = DivisionsLayer({ active: true });

  render() {
    // const dragPan = window.innerWidth > 690
    let zoom = window.innerWidth > 690 ? 4 : 2.5
    let height = window.innerWidth > 690 ? 600 : 400
    return (
      <div style={{height: height}}>
        <AvlMap
          layers={[
            this.DivisionLayer
          ]}
          layerProps={{
            'divisions':{
              latLng: this.props.latLng,
              divisions: this.props.divisions
            }
          }}
          height={'100%'}
          center={[0,0]}
          dragPan={false}
          scroolZoom={false}
          zoom={zoom}
          onChange={this.props.onChange}
          fips={''}
          styles={[
              {name: 'Blank', style: 'mapbox://styles/am3081/ckaml4r1e1uip1ipgtx5vm9zk'}
          ]}
          MAPBOX_TOKEN={'pk.eyJ1IjoiYW0zMDgxIiwiYSI6IkxzS0FpU0UifQ.rYv6mHCcNd7KKMs7yhY3rw'}
          sidebar={false}
          mapControls={'bottomRight'}
        />
      </div>
    )
  }
}

export default NationalLanding