import React from "react"
import 'styles/tailwind.css';

export default  (props) => (
  <div className="bg-white overflow-hidden shadow-md sm:rounded-sm mb-6">
    <div className="border-b border-gray-200 px-4 pt-3 pb-2 sm:px-6">
     <h4  
     	style={{fontFamily: 'TIMMONS NY', fontSize: '3em', color: '#101010', letterSpacing: '1px', textDecoration: 'none'}}
     >
     	WELCOME ...</h4>
    </div>
    <div className="px-4 py-5 sm:p-6">
      <h5 className='text-blue-600'>Verify Your Email Address </h5>
    </div>  
  </div>
)