import React,{ Component } from 'react';

import Layout from 'pages/Auth/components/Layout'

// import ElectionTable from './components/ElectionsTable'
// import StateSelector from 'pages/Auth/Candidate/components/campaign/components/StateSelector'
// import { Dropdown } from 'semantic-ui-react'

// import get from 'lodash.get'



class ElectionManager extends Component {
  state = {
      year: 2020,
      office_state:  'NY',
      activeOrg: 'United States Congress',
      isLoading: false,
      orgs: [],
      options: []
  }

  render () {
  	return (
		  <Layout>
		    <div>  
				<div className="shadow-md sm:rounded-md">
				    <div className="px-4 py-5 bg-white sm:p-6">
		     	 		Sign Up Manager
		     	  </div>
		     	</div>
		    </div>
		  </Layout>
		)
	}
}

export default {
  name: 'Sign Up Manager',
  path: '/admin/signups',
  mainNav: false,
  authed: true,
  authLevel: 5,
  component: ElectionManager
}