import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import Arrow from 'components/util/arrow'
import RaceThumb from 'components/race/RaceThumb'
import Loading from 'components/util/Loading'
import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'


import styled from 'styled-components'

let SidebarContainer = styled.div`
  flex-basis: 275px;
  padding: 20px;
  padding-top: 40px;
  padding-left: 0px;
  @media (max-width: 1279px) {
    display: none;
  }
`

let SidebarList = styled.ul`
  list-style:none;
  position: sticky; 
  top: 10px;
  border-left: 3px solid #999;
  padding-left: 10px;
  font-family: 'GT Pressura Mono', sans;
  li {
    padding: 5px 2px;
    line-height: 1;
    font-size: 0.95em;
    text-align: left;
    color: #999
  }
  li.office:hover {
      color: #000;
      cursor: pointer;
  }
  li.gov:hover {
    color:#999;
  }
`


class DivElections extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.changeYear = this.changeYear.bind(this)
    // this.fetchFalcorDeps = this.fetchFalcorDeps.bind(this)
  }

  fetchFalcorDeps (props) {
    props = props ? props : this.props
    this.setState({loading: true})
    return this.props.falcor.get(
      ['divisionsById', props.divisions, 'offices', 'length'],
      ['divisionsById',
        props.divisions,
        'offices',
        {to: 10},
        ['name', 'level', 'term', 'lastElectionYear', 'id']
      ],
      ['divisionsById',
        props.divisions,
        'offices',
        {to: 10},
        'government',
        ['name', 'id']
      ],
      ['divisionsById',
        props.divisions,
        'offices',
        {to: 10},
        'races',
        props.year,
        'length'
      ]
    ).then(data => {
      this.setState({loading: false})
    })
  }

  // shouldComponentUpdate (nextProps) {
  //   return true
  // }

  componentDidUpdate (prevProps) {
    if(prevProps.year !== this.props.year) {
      this.fetchFalcorDeps(this.props)
    }

    var updateDivsions = false 
    prevProps.divisions.forEach(div => {
      if (this.props.divisions.indexOf(div) === -1) {
        updateDivsions = true
      }
    })
    if (updateDivsions) this.fetchFalcorDeps(this.props)
  }

  shallowProps (props) {
    let divisionsById = get(props.graph, 'divisionsById', {})
    //console.log('------------------------------------------')
    var officeDivs = props.divisions
    .filter((div) => {
      let offices = Object.values(get(divisionsById,`[${div}].offices`, {}))
        .map(office => getRef(props.graph,office))
        .filter(d => d)

      return offices.length > 0
    })
    

    var orgs = {}
    var officeByOrg = officeDivs.reduce((prev, divId) => {
      var offices = Object.values(divisionsById[divId].offices)
        .map(office => getRef(props.graph,office))
        .filter(d => d)

      // console.log('officess', divId, offices)
      offices.forEach(office => {
        const org = getRef(props.graph, office.government)
        var orgId = get(org,'id', null)
         
        if (orgId) {
          if (!prev[orgId]) {
            orgs[orgId] = org
            prev[orgId] = []
          }

          // const races = Object.values(office.races[props.year])
          //   .map(race => getRef(props.graph, race))
          //   .filter(d => d)

          // console.log()


          if (get(office,`races[${props.year}].length`, 0) > 0) {
            prev[orgId].push(office)
          }
        }
        
      })
      return prev
    }, {})
    //console.log('------------------------------------------')
    
    return {
      officeDivs,
      officeByOrg,
      orgs
    }
  }

  changeYear(val) {
    if(this.props.onYearChange) {
      this.props.onYearChange(this.props.year + val)
    }
  }

  render () {
    
    var data = this.shallowProps(this.props)
    var activeOrgs = Object.keys(data.officeByOrg)
      .filter(orgId => data.officeByOrg[orgId].length > 0)
    var content = (
      activeOrgs
      .map(orgId => {
        return (
          <ul className='grid' key={orgId}>
            <h4 style={{textAlign: 'right', width: '100%'}}>
              {data.orgs[orgId].name ? data.orgs[orgId].name.toUpperCase() : ''}
            </h4>
            {
              data.officeByOrg[orgId].map(d => {
                // console.log('de rt', orgId, d.id)
                return (
                  <RaceThumb key={d.id} officeId={d.id} year={this.props.year} />
                )
              })
            }
          </ul>
        )
      })
    )



    return (
      <div className='container'>
        <div className='row'>
          <div className='col-12' style={{display:'flex', justifyContent: 'center'}}>
             
              <h1 style={{fontSize: 80, textAlign:'center'}}>
                <div style={{display: 'inline-block'}}onClick={this.changeYear.bind(null, -1)}> 
                  <Arrow rotate='90'/>
                </div>
                  {this.props.year}
                <div style={{display: 'inline-block'}}onClick={this.changeYear.bind(null, 1)}> 
                  <Arrow rotate='270' onClick={this.changeYear.bind(null, 1)}/>
                </div>
              </h1>
              
            </div>
        </div>
        <div style={{display: 'flex'}}>
          <SidebarContainer>
            <SidebarList >
            {
              activeOrgs
                .map(orgId => {
                  return (
                     
                     <div key={orgId} style={{marginBottom: 30}}>
                       <li key={orgId} style={{fontWeight: 600, fontSize: '0.85em'}}>
                        {data.orgs[orgId] ? data.orgs[orgId].name.toUpperCase() : ''}
                       </li>
                        {
                          data.officeByOrg[orgId].map((d,i) => {
                            return (
                              <li className='office' key={i}>
                                {get(data, `officeByOrg[${orgId}][${i}].name`, '').toUpperCase()}
                              </li>
                            )
                          })
                        }
                      </div>
                     
                  )
                })
            }
            </SidebarList>
          </SidebarContainer>
          <div className='row' style={{flex: 1}}> 
            <div className='col-12' style={{maxWidth: 1200}}>
              {this.state.loading ? <Loading/> : content}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function select (state) {
  return {
    graph: get(state, 'graph', {})
  }
}

export default connect(select)(reduxFalcor(DivElections))



