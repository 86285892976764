import React from 'react'
//import styles from './PhotoManager.scss'
// import Dropzone from 'react-dropzone';
import get from 'lodash.get'
import { host } from 'store/modules/falcorGraph'
import Modal from '../../../Modal'

import ImageEditor from './ImageEditor'
import ReactS3Uploader from 'react-s3-uploader'

class PhotoManager extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false,
      currentImage: props.state[props.name] ? props.state[props.name] :   null,
      progress: null,
      error: null
    }
    this._toggleModal = this._toggleModal.bind(this)
    this._onProgress = this._onProgress.bind(this)
    this._onFinish = this._onFinish.bind(this)
    this._onError = this._onError.bind(this)
    this._onSave = this._onSave.bind(this)
    this._Save = this._Save.bind(this)
    //this._onEdit = this._onEdit.bind(this)
  }

  static getDerivedStateFromProps(nextProps) {
   return {
    value: nextProps.state[nextProps.name]
   }
  }

  renderLaunch () {
    return (
      <div className="flex items-center">
        <span className="h-20 w-20 rounded-full overflow-hidden bg-gray-100 bg-cover" style={{backgroundImage: `url(${get(this.props.state,`${this.props.name}`, '')})`}}>
          {get(this.props.state,`${this.props.name}`, false) ?  '' :
          (<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>)
        }
        </span>
        <span className="ml-5 rounded-md shadow-sm">
          <button onClick={this._toggleModal} type="button" className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
            Change
          </button>
        </span>
      </div>
    )
  }

  _toggleModal () {
    this.setState({showModal: !this.state.showModal, currentImage: null})
  }

  _onProgress (prog) {
    this.setState({
      progress: prog
    })
  }

  _onError (error) {
    console.log('Error: ', error)
    this.setState({
      error: error
    })
  }

  _onFinish (data) {
    this.setState({
      currentImage: data.publicUrl,
      progress: null
    })
  }

  _onSave (url) {
    this.props.onChange({target: {name: this.props.name, value: url}})
    this.setState({showModal: false})
  }

  _Save (e) {
    console.log('clicking', )
    e.preventDefault()
    this.refs.ImageEditor._handleSave()
  }

  renderUpload () {
    if (this.state.currentImage) {
      return (
        <div>
          <ImageEditor
            ref="ImageEditor"
            image={this.state.currentImage}
            type={this.props.type}
            typeId={this.props.typeId}
            onSave={this._onSave}
          />
        </div>
      )
    } else if (this.state.progress) {
      return (
        <div>
          {this.state.progress}
        </div>
      )
    } else {
      return (
        <div className='w-full p-2'>
          <div className='w-full py-32 text-center border-dashed border-2 border-gray-300'>
            <ReactS3Uploader
              signingUrl='/img/upload/'
              accept='image/*'
              onProgress={this._onProgress}
              onError={this._onError}
              onFinish={this._onFinish}
              uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
              contentDisposition='auto'
              server={host} />
          </div>
        </div>
      )
    }
  }

  renderModal (pictureStyle, name) {
    return (
      <Modal open={this.state.showModal} toggle={this._toggleModal}>
        {this.renderUpload()}
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button onClick={this._toggleModal} type="button" className="m-2 py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
            Cancel
        </button>
        
        <button 
          onClick={this._Save} 
          disabled={!this.state.currentImage} 
          className={`py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white ${!this.state.currentImage ? 'bg-gray-400 hover:bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-500'} shadow-sm  focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out`}
        >
          Save
        </button>
        
      </div>
      </Modal>
    )
  }

  render () {

    return (
      <div>
        {this.renderLaunch()}
        {this.renderModal()}
      </div>
    )
  }
}



export default PhotoManager

