import React, { Component } from 'react';
import 'styles/tailwind.css';

import Sidebar from 'layouts/components/Sidebar'
import HeaderBar from 'layouts/components/HeaderBar'


class Layout extends Component {
  state = {
    sidebarOpen: false
  }

  toggleSidebar = () => {
    this.setState({sidebarOpen: !this.state.sidebarOpen})
  } 

  render () {
    return (
      <div style={{backgroundColor: '#f0f7f9'}}>
        <div style={{position: 'fixed', left: 0, top: 0, width: '100%', zIndex: 3, backgroundColor: '#f0f7f9'}} > 
          <div className="max-w-5xl mx-auto sm:px-6 lg:px-8" style={{maxWidth: 1380}}>
            <HeaderBar toggle={this.toggleSidebar} menu={this.props.headerMenu}/>
          </div>
        </div>
      	<div className="mx-auto sm:px-6 lg:px-8" style={{maxWidth: 1380}}>
          <div className="min-h-screen flex">
            <Sidebar 
              open={this.state.sidebarOpen} 
              toggle={this.toggleSidebar}
            />
            <div className="w-0 flex-1 ">
              <main className="flex-1 relative z-0 pt-2 pb-6 focus:outline-none md:py-6 mt-16">
                <div className="">
                  {this.props.children}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Layout