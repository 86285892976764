import React from 'react';
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import { Link } from 'react-router-dom'
import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'


import 'styles/tailwind.css';



class CampaignTable extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true
    }
  }
  
  fetchFalcorDeps () {
		return this.props.falcor.get(
      ['campaigns','byIndex', this.props.index, ['id', 'campaign_data', 'year', 'payment']],
      ['campaigns','byIndex', this.props.index, 'person', ['name', 'party']],
      ['campaigns','byIndex', this.props.index, 'office', 'name'],
      ['campaigns','byIndex', this.props.index, 'office', 'division', 'name']
    )
		.then(r => {
			this.setState({loading:false})
		})
  		
  }

  render() {
    if(this.state.loading) {
      return (
        <tr>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                  <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </div>

                <div className="ml-4">
                  <div className="text-sm leading-5 font-medium text-gray-900"></div>
                  <div className="text-sm leading-5 text-gray-500"></div>
                </div>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              <div className="text-sm leading-5 text-gray-900"></div>
              <div className="text-sm leading-5 text-gray-500"></div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                Active
              </span>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
              Owner
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
              
            </td>
          </tr>
      )
    }
    // console.log('campaign', this.props.campaign_data)
    return(
      <tr>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-full overflow-hidden bg-cover" style={{backgroundImage: `url(${get(this.props,`campaign_data.photo`, '')})`}}>
              <span className="h-full w-full rounded-full overflow-hidden" >
                {get(this.props,`campaign_data.photo`, false) ?  '' :
                (<svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>)
                }
              </span>
            </div>
            <div className="ml-4">
              <div className="text-sm leading-5 font-medium text-gray-900">{this.props.name}</div>
              <div className="text-sm leading-5 text-gray-500">{this.props.party}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div className="text-sm leading-5 text-gray-900">{this.props.office}</div>
          <div className="text-sm leading-5 text-gray-500">{this.props.division}</div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
          {this.props.year}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${this.props.payment === 'Inactive' ? 'red' : 'green'}-100 text-${this.props.payment === 'Inactive' ? 'red' : 'green'}-800`}>
            <Link to={`/candidate/campaigns/status/${this.props.id}`}>{this.props.payment}</Link>
          </span>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
          <Link to={`/candidate/campaigns/edit/${this.props.id}`} className="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline">Edit</Link>
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state,p) => {
  let graph = state.graph
  let campaign = getRef(graph, get(state, `graph.campaigns.byIndex[${p.index}]`, {}))
  let payment = campaign.payment ? campaign.payment : 'Inactive'
  let person = getRef(graph, campaign.person)
  let office = getRef(graph, campaign.office)
  let division = getRef(graph, office.division)
  let campaign_data = get(campaign, 'campaign_data.value', {})
  
  return {
    id: campaign.id,
    name: person.name,
    party: person.party,
    year: campaign.year,
    office: office.name,
    division: division.name,
    campaign_data,
    payment
  };
};

export default  connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(CampaignTable))