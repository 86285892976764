import React, { Component } from 'react';
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'

import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'

import LoadingPage from "components/loading/LoadingPage"

import FormSection from 'layouts/components/Forms/FormSection'
import Alert from 'layouts/components/Alerts/Alert'
import SignUpDetails from 'pages/Auth/Signup/SignUpDetails'

import CampaignConfig from './CampaignConfig'

let defaultData = {
  pitch:'',
  issues:[],
  video: '',
  platform: '',
  endorsements: [],
  press: [],
  address: '',
  message: false
}

class CampaignEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    }
  }
  
  fetchFalcorDeps () {
    return this.props.falcor.get(
      ['campaignsById', this.props.campaignId, ['id', 'campaign_data', 'year', 'payment']],
      ['campaignsById', this.props.campaignId, 'person', ['name', 'party']],
      ['campaignsById', this.props.campaignId, 'office', 'name'],
      ['campaignsById', this.props.campaignId, 'office', 'division', 'name']
    )
    .then(r => {
      let campaign_data = Object.assign(
        defaultData,
        get(r, `json.campaignsById[${this.props.campaignId}].campaign_data`, {})
      )
      campaign_data.loading = false
      campaign_data.message = this.state.message
      console.log('wah wah wah')
      this.setState(campaign_data)
    })   
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  clearMessage = () => {
    this.setState({message: false})
  }

  handleSubmit = e => {
      e.preventDefault();
      this.setState({message: false})
      // let attributes = Object.keys(this.state)
      return this.props.falcor.set({
          paths: [
              ['campaignsById',this.props.campaignId,'campaign_data']
          ],
          jsonGraph: {
              
            campaignsById :{
                [this.props.campaignId] : {
                    campaign_data : {
                        $type: 'atom',
                        value: JSON.stringify(this.state)
                      }
                }
            }
          }
      })
      .then(response => {
        console.log('got a response', response)
        this.setState({message: {
            color: 'green', 
            title: 'Sucess - Campaign Saved',
            dismiss: this.clearMessage 
          }
        })
        //this.props.sendSystemMessage(`Campaign was successfully edited.`, {type: "success"});
      })
  }


  render () {
    if(this.state.loading) {
      return (<LoadingPage width={'100%'} height={'80vh'}/>)
    }

    console.log('<CampaignEdit> state',this.state.message)
    return (
      <React.Fragment>
        <div className="flex items-center ml-2">
          <div className="flex-shrink-0">
            <button  className="flex-shrink-0 max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
              <span className="inline-block h-20 w-20 rounded-full overflow-hidden bg-blue-100 bg-cover" style={{backgroundImage: `url(${get(this.props,`campaign_data.photo`, '')})`}}>
                {get(this.props,`campaign_data.photo`, false) ?  '' :
                (<svg className="h-full w-full text-blue-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>)
                }
              </span>
            </button>
          </div>
          <div className="ml-4 pt-7">          
            <div className="text-6xl leading-5 font-medium text-gray-900 inline theme-font">{this.props.name}</div>
            <div className="text-lg leading-5 text-gray-500"> for {this.props.office} {this.props.division}</div>
          </div>
        </div>
        {
          CampaignConfig
            .filter(config => this.props.payment || !config.reqPayment )
            .map((config,i) => (
            <FormSection
              key={i} 
              onChange={this.handleChange}
              state={this.state}
              {...config} 
            />
          ))
        }
        {
          this.props.payment ? '':
          (<div className="pt-4">
            <SignUpDetails to={`/candidate/campaigns/status/${this.props.campaignId}`}className={'-mx-4 md:-mx-6'}/>
          </div>)

        }
        <div className="pt-4">
          <div className="flex justify-end">
            {this.state.message ? <Alert {...this.state.message} /> : ''}
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button onClick={this.handleSubmit} type="submit" className="inline-flex justify-center py-2 px-20 border border-transparent text-lg leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                Save
              </button>
            </span>
          </div>
        </div>
      </React.Fragment>
    
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state,p) => {
let graph = state.graph
let campaignId = p.campaignId
  let campaign =  get(graph, `campaignsById[${campaignId}]`,{})
  let person = getRef(graph, campaign.person)
  let office = getRef(graph, campaign.office)
  let division = getRef(graph, office.division)
  let payment = get(campaign, `payment`, false)
  
  return {
    campaignId,
    id: campaign.id,
    name: person.name,
    party: person.party,
    year: campaign.year,
    office: office.name,
    division: division.name,
    campaign_data: get(campaign, 'campaign_data.value', {}),
    payment
  };
};
  

export default connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(CampaignEdit))
