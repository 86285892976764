import React from 'react'
import { Dropdown } from 'semantic-ui-react'

import 'styles/semantic-ui.css'

let parties = {
  'rep': 'Republican Party',
  'dem': 'Democratic Party',
  'wfp': 'Working Families Party',
  'ind': 'Independence Party',
  'con': 'Conservative Party'
}

const partyOptions = Object.keys(parties).map(abbr => {
  return {
    key: abbr, value: parties[abbr], text: parties[abbr]
  }
}) 
const noop = () => {}

const PartySelector = ({value, onChange=noop}) => (
  <Dropdown
    placeholder='Select State'
    fluid
    search
    multiple
    selection
    id='party'
    name={'party'}
    onChange={onChange}
    options={partyOptions}
    value={value}
  />
)

export default PartySelector