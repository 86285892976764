import React from 'react'
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'
import {
  withRouter
} from 'react-router-dom'
// import { Link } from 'react-router'
import ResponsiveMap from 'components/util/map/ResponsiveMap'
import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'
// import graphGet from 'components/util/graphGet'


// import Loading from 'components/util/Loading'

class RaceThumb extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.link = this.link.bind(this)
  }

  fetchFalcorDeps (props) {
    props = props ? props : this.props
    this.setState({loading: true})
    return this.props.falcor.get(
      ['officesById', props.officeId, 'races', props.year, 'length'],
      ['officesById', props.officeId,
        ['name', 'level', 'term', 'lastElectionYear', 'id']],
      ['officesById', props.officeId, 'organization',['name', 'id']],
      ['officesById', props.officeId, 'division', ['ocdId','geojson','name']],
      ['officesById', props.officeId, 'government', ['name', 'id']],
      ['officesById', props.officeId, 'races', props.year, {from: 0, to: 1},
        'election',['name','date','type','election_date']]
    ).then(data => {
      //console.log('RaceThumb Data', data)
      this.setState({loading: false})
    })
  }

  componentDidUpdate (prevProps) {
    if(prevProps.year !== this.props.year) {
      this.fetchFalcorDeps(this.props)
    }
  }


  shallowProps (props) {

    let graph = props.graph
    var office = get(graph, `officesById[${props.officeId}]`, {})
    var officeDivision = getRef(graph, office.division)
    var theGeo = officeDivision.geojson ? officeDivision.geojson.value : null
    // console.log('rt geo', theGeo)
    let general = getRef(graph, getRef(graph,get(office,`races[${props.year}][1]`, {})).election)
    let primary = getRef(graph, getRef(graph,get(office,`races[${props.year}][0]`, {})).election)

    return {
      officeName: office.name ? office.name : '',
      officeTerm: office.term ? office.term : '',
      officeLevel: office.level ? office.level : '',
      officeOrg: getRef(graph, get(office, `organization`, {})).name,
      officeGeneralDate: general ? general.date : '',
      officePrimaryDate:  primary ? primary.date : '',
      officeGeo: theGeo,
      divisionName: officeDivision.name
    }
  }

  changeYear(val) {
    if(this.props.onYearChange) {
      this.props.onYearChange(this.props.year + val)
    }
  }

  link (location) {
    console.log('test' , this.props)
    this.props.history.push(location)
  }

  render () {
    var data = this.shallowProps(this.props)
    var raceLink = `/race/${this.props.officeId}/${this.props.year}`
    return (
      <li className="card-item col-xs-12"  style={{backgroundColor: '#fff' }} onClick={this.link.bind(null,raceLink)}>
        <div className="wrapper">
          <div className="rank-column background-theme">
           <ResponsiveMap
              mapDiv={'officeMap' + this.props.officeId}
              geo={data.officeGeo}
            />
          </div>
          <div className="info-column">
            <div className="medium-show">
              <div className="player-main">
                <h3>{data.officeName}</h3>
                <h4 style={{fontFamily: `'GT Pressura Mono',sans`, fontWeight: 400, color: '#555' ,lineHeight: 1}}>{data.officeOrg}</h4>
                
                
              </div>
              <div className="player-stats">
                <ul>
                  <li>
                    <div className="stat-wrap">
                      <span className="title">TERM</span>
                      <span className="number">{data.officeTerm}</span>
                    </div>
                  </li>
                  <li>
                    <div className="stat-wrap">
                      <span className="title">PRV ELCT</span>
                      <span className="number">{this.props.year - data.officeTerm}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="player-meta">
                <ul>
                  <div className="medium-column">
                    <li className="ringer-stat color-theme">
                      <span className="label">OFFICE LEVEL</span>
                      <span className="value">{data.officeLevel.toUpperCase()}</span>
                    </li>
                    <li><span className="label">District</span><span className="value uppercase" >{data.divisionName}</span></li>
                  </div>
                  <div className="medium-column">
                    <li><span className="label">Primary Election</span><span className="value">{data.officePrimaryDate}</span></li>
                    <li><span className="label">General Election</span><span className="value">{data.officeGeneralDate}</span></li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }
}

function select (state) {
  return {
    // user: state.user.toJS(),
    officesById: state.graph.officesById || {},
    graph: state.graph
  }
}

export default connect(select)(withRouter(reduxFalcor(RaceThumb)))


