import React from 'react';
import 'styles/tailwind.css';

import Layout from 'pages/Auth/components/Layout'

import OnBoard from './components/OnBoard'
// import Stats from './components/Stats'
import CampaignList from './components/campaign/CampaignList'

const Dashboard = () => (
  <Layout>
    <OnBoard />
    <CampaignList />
    {/*<Stats />*/}
  </Layout>
)

export default {
  name: 'Dashboard',
  path: '/candidate/dashboard',
  mainNav: false,
  authed: true,
  authLevel: 0,
  component: Dashboard
}