import React from 'react';
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'


import Modal from 'layouts/components/Modal'
import CampaignNew from './CampaignNew'
import CampaignTable from './components/CampaignTable'

import 'styles/tailwind.css';


class CampaignList extends React.Component {
  constructor() {
    super();
    this.state = {
      modal: false
    }
  }

  toggleModal = () => {
  	this.setState({modal: !this.state.modal})
  }
  

  render() {
    return (
    	<div className="bg-white overflow-hidden shadow-md sm:rounded-sm mb-6">
		    <div className="border-b border-gray-200 px-4 pt-3 pb-2 sm:px-6">
		      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
			    	<div className="ml-3 mt-2">
				       
						    <h4 style={{fontFamily: 'TIMMONS NY', fontSize: '3em', color: '#101010', letterSpacing: '1px', textDecoration: 'none'}}>CAMPAIGNS</h4>
						    
			    	</div>
			    	<div className="ml-4  flex-shrink-0">
			      	{/*<span className="inline-flex rounded-md shadow-sm">
			        	<button onClick={this.toggleModal} type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
			        	  New Campaign
			        	</button>
			      	</span>*/}
			    	</div>
			  	</div>
		    </div>
		    <div className="">
		      <CampaignTable toggle={this.toggleModal} />
		    </div>
		    <Modal open={this.state.modal} toggle={this.toggleModal}>
		    	<CampaignNew toggle={this.toggleModal}/>
		    </Modal>
		  </div>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    graph: state.graph
  };
};

export default  connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(CampaignList))