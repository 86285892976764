import React from 'react'

class Arrow extends React.Component {
  render() {
    return (
    	<div style={{transform: 'rotate(' + this.props.rotate + 'deg)', width: 40, display: 'inline-block', padding: 5, cursor: 'pointer'}} >
	    	<svg viewBox="0 0 39.5 39.5">
	    		<defs><clipPath id="clip-path"><rect className="cls-1" width="39.5" height="39.5"></rect></clipPath></defs><title>newarrow</title><g className="cls-2"><path className="arrow-fill" d="M19.75,39.5A19.75,19.75,0,1,1,39.5,19.75,19.75,19.75,0,0,1,19.75,39.5Zm0-38A18.25,18.25,0,1,0,38,19.75,18.25,18.25,0,0,0,19.75,1.5Z"></path></g><rect className="arrow-fill" x="19" y="11.75" width="1.5" height="15"></rect><rect className="arrow-fill" x="18.77" y="22.59" width="8.15" height="1.5" transform="translate(-9.81 22.99) rotate(-45)"></rect><rect className="arrow-fill" x="15.89" y="19.26" width="1.5" height="8.15" transform="translate(-11.63 18.6) rotate(-45)"></rect>
	    	</svg>
	   	</div>
    );
  }
}

export default Arrow
