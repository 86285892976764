import React from 'react'
import { Link } from 'react-router-dom'

export function Free ({to='/candidate/create', className}) { 
  return (
  <div className={'relative ' +className}>
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
        <div className="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
          <h3 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
            Claim Your Campaign for Free
          </h3>
          {/*<p className="mt-6 text-base leading-6 text-gray-500">
            With Full Access Membership you will receive all the tools for a web campaign gaining additional unlimited access to:
          </p>*/}
          <div className="mt-8">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                What's included
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
              <li className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm leading-5 text-gray-700">
                  A short personalized elevator pitch in your own words;
                </p>
              </li>
              <li className="flex items-start lg:col-span-1 lg:mt-0">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm leading-5 text-gray-700">
                  A dedicated candidate page providing more detailed information to the public;
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
          <p className="text-lg leading-6 font-medium text-gray-900">
            Claim Your Campaign
          </p>
          <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
            <span>
              FREE
            </span>
            <span className="ml-3 text-xl leading-7 font-medium text-gray-500">
              
            </span>
          </div>
          {/*<p className="mt-4 text-sm leading-5">
            <Link to='/candidate/create' className="font-medium text-gray-500 underline">
              Learn about our membership policy
            </Link>
          </p>*/}
          <div className="mt-6">
            <div className="rounded-md shadow">
              <Link to={to} className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Get Access
              </Link>
            </div>
          </div>
         {/* <div className="mt-4 text-sm leading-5">
            <Link href="#" className="font-medium text-gray-900">
               Sample Candidate Page
            </Link>
          </div>*/}
        </div>
      </div>
    </div>
  </div>
)
}



export default ({to='/candidate/create', className}) => (
  <div className={'relative ' + className}>
    <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
        <div className="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
          <h3 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
            Full Election Membership
          </h3>
          <p className="mt-6 text-base leading-6 text-gray-500">
            With Full Access Membership you will receive all the tools for a web campaign gaining additional unlimited access to:
          </p>
          <div className="mt-8">
            <div className="flex items-center">
              <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                What's included
              </h4>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
              <li className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm leading-5 text-gray-700">
                  Access to the Member Resources and Sample Campaign Ideas
                </p>
              </li>
              <li className="flex items-start lg:col-span-1 lg:mt-0">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm leading-5 text-gray-700">
                  Link your profile to all of your social media and websites;
                </p>
              </li>
              <li className="flex items-start lg:col-span-1 lg:mt-0">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm leading-5 text-gray-700">
                  Post video links and YouTube videos;
                </p>
              </li>
              <li className="flex items-start lg:col-span-1 lg:mt-0">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                </div>
                <p className="ml-3 text-sm leading-5 text-gray-700">
                  Post endorsements in real time;
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
          <p className="text-lg leading-6 font-medium text-gray-900">
            Claim Your Campaign
          </p>
          <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
            <span>
              $99
            </span>
            <span className="ml-3 text-xl leading-7 font-medium text-gray-500">
              USD
            </span>
          </div>
          {/*<p className="mt-4 text-sm leading-5">
            <Link to='/candidate/create' className="font-medium text-gray-500 underline">
              Learn about our membership policy
            </Link>
          </p>*/}
          <div className="mt-6">
            <div className="rounded-md shadow">
              <Link to={to} className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Get Access
              </Link>
            </div>
          </div>
         {/* <div className="mt-4 text-sm leading-5">
            <Link href="#" className="font-medium text-gray-900">
               Sample Candidate Page
            </Link>
          </div>*/}
        </div>
      </div>
    </div>
  </div>
)


