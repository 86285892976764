import React, { Component } from 'react';
import { connect } from 'react-redux'
import { reduxFalcor } from 'utils/redux-falcor'

import get from 'lodash.get'
import getRef from 'utils/falcorGetRef'

// import StripeForm from './components/StripeForm'
import LoadingPage from "components/loading/LoadingPage"
import FormSection from 'layouts/components/Forms/FormSection'

const Config = [
  {
    title: 'Campaign Info',
    info: 'The details of this campaign',
    inputs: [
      {
        label: 'Candidate',
        name: 'name',
        type: 'Text',
        readOnly: true
      },
      {
        label: 'Office',
        name: 'office',
        type: 'Text',
        readOnly: true
      },
      {
        label: 'Party',
        name: 'party',
        type: 'Text',
        readOnly: true
      },
      {
        label: 'Year',
        name: 'year',
        type: 'Text',
        readOnly: true
      },
      {
        label: 'Status',
        name: 'payment',
        type: 'Text',
        readOnly: true
      }
    ]
  }
]

let PaymentConfig = {
  title: 'Activate Campaign',
  info: 'All info from this campoaign will be visible to public visitors.',
  inputs: [
    {
      label: 'Activate',
      name: 'activation',
      type: 'StripePayment'  
    }
  ]
}


class CampaignPayment extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    }
  }
  
  fetchFalcorDeps () {
    return this.props.falcor.get(
      ['campaignsById', this.props.campaignId, ['id', 'campaign_data', 'year', 'payment']],
      ['campaignsById', this.props.campaignId, 'person', ['name', 'party']],
      ['campaignsById', this.props.campaignId, 'office', 'name'],
      ['campaignsById', this.props.campaignId, 'office', 'division', 'name']
    )
    .then(r => {
      let payment = get(r, `json.campaignsById[${this.props.campaignId}].payment`, 'Inactive')
      payment = payment ? payment : 'Inactive'

      this.setState({
        payment,
        name: this.props.name,
        party: this.props.party,
        office: this.props.office,
        year: this.props.year,
        activation: this.state.activation ? this.state.activation : payment === 'Inactive' ? {} : false,
        loading:false
      })
    })   
  }

  handleChange = e => {
    console.log('change', e.target.name)
    this.setState({[e.target.name]: e.target.value})
  }

  handlePayment = (payment) => {
    console.log('<CampaignPayment.handlePayment>', payment)
    if(payment.type === 'error') {
      this.setState({
        activation: payment
      })
    } else{
      // update payment on server
      return this.props.falcor.set({
          paths: [
              ['campaignsById',this.props.campaignId,'payment']
          ],
          jsonGraph: {
              
            campaignsById :{
                [this.props.campaignId] : {
                    payment : 'Active'
                }
            }
          }
      })
      .then(response => {
          this.setState({
            payment: 'Active',
            activation: payment
          })
      })

    }
  }



  render () {
    console.log('render',)
    if(this.state.loading) {
      return (<LoadingPage width={'100%'} height={'80vh'}/>)
    }

    return (
      <React.Fragment>
        <div className="flex items-center ml-2">
          <div className="flex-shrink-0">
            <button  className="flex-shrink-0 max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline">
              <span className="inline-block h-20 w-20 rounded-full overflow-hidden bg-blue-100">
                <svg className="h-full w-full text-blue-700" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            </button>
          </div>
          <div className="ml-4 pt-7">          
            <div className="text-4xl leading-5 font-medium text-gray-900 inline" style={{fontFamily: 'TIMMONS NY', textTransform:'uppercase', fontSize: '4em'}}>{this.props.name}</div>
            <div className="text-lg leading-5 text-gray-500"> for {this.props.office} {this.props.division}</div>
          </div>
        </div>
        {
          Config.map((config,i) => (
            <FormSection
              key={i} 
              onChange={this.handleChange}
              state={this.state}
              {...config} 
            />
          ))
        }
        {
          this.state.activation ? (
            <FormSection
              onChange={this.handlePayment}
              state={this.state}
              {...PaymentConfig} 
            />
          ) : ''
        }
      </React.Fragment>
    
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state,p) => {
  let graph = state.graph
  let campaignId = p.campaignId
  let campaign =  get(graph, `campaignsById[${campaignId}]`,{})
  let person = getRef(graph, campaign.person)
  let office = getRef(graph, campaign.office)
  let division = getRef(graph, office.division)
  
  return {
    campaignId,
    id: campaign.id,
    name: person.name,
    party: person.party,
    year: campaign.year,
    office: office.name,
    division: division.name
  };
};
  

export default connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(CampaignPayment))
