import React from 'react';

import Layout from 'pages/Auth/components/Layout'


const Dashboard = () => (
  <Layout>
    Admin Dashboard
  </Layout>
)

export default {
  name: 'Admin Dashboard',
  path: '/admin/dashboard',
  mainNav: false,
  authed: true,
  authLevel: 5,
  component: Dashboard
}