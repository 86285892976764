import React, { Component } from 'react';
import 'styles/tailwind.css';

import Layout from 'pages/Auth/components/Layout'

import CampaignView from 'components/campaign/CampaignView'

class PreviewCampaign extends Component {
  render () {
    const headerMenu = {
      title: 'Edit Campaign', 
      items: [
        {
          name: 'Edit',
          to:  `/candidate/campaigns/edit/${this.props.match.params.campaignId}`
         
        },
        {
          name: 'Preview',
          to:  `/candidate/campaigns/${this.props.match.params.campaignId}`,
          active: true
        },
        {
          name: 'Status',
          to:  `/candidate/campaigns/status/${this.props.match.params.campaignId}`
        }
      ]
    } 

    return (
      <Layout headerMenu={headerMenu}>
        <CampaignView campaignId={this.props.match.params.campaignId} />
      </Layout>
   
    )
  }
}


export default
{
  name: 'Preview Campaign',
  path: '/candidate/campaigns/:campaignId',
  mainNav: false,
  authed: true,
  exact: true,
  authLevel: 0,
  component: PreviewCampaign
}