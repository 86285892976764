import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import get from 'lodash.get'
import styled from 'styled-components'

let BInput = styled.input`
    padding-left: 10px;
    
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    :focus{
        outline: none;
    }
`


class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: get(this.props, `address`, ''),
      geocodeResults: null,
      loading: false
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderGeocodeFailure = this.renderGeocodeFailure.bind(this);
    this.renderGeocodeSuccess = this.renderGeocodeSuccess.bind(this);
  }



  handleSelect(address) {
    this.setState({
      address,
      loading: true
    });
    if (this.props.onGeocode) this.props.onGeocode({ loading: true });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Success Yay', { lat, lng });
        if (this.props.onGeocode)
          this.props.onGeocode({ loading: false, coords: [lat, lng], address });
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        console.log('Oh no!', error);
        if (this.props.onGeocode)
          this.props.onGeocode({ loading: false, err: error });
        this.setState({
          loading: false
        });
      });
  }
  //   /* NOTE: Using callback (Deprecated version) */
  //   // geocodeByAddress(address,  (err, { lat, lng }) => {
  //   //   if (err) {
  //   //     console.log('Oh no!', err)
  //   //     this.setState({
  //   //       geocodeResults: this.renderGeocodeFailure(err),
  //   //       loading: false
  //   //     })
  //   //   }
  //   //   console.log(`Yay! got latitude and longitude for ${address}`, { lat, lng })
  //   //   this.setState({
  //   //     geocodeResults: this.renderGeocodeSuccess(lat, lng),
  //   //     loading: false
  //   //   })
  //   // })
  // }

  // handleChange (address) {
  //   this.setState({
  //     address,
  //     geocodeResults: null
  //   })
  // }
  handleChange = address => {
    this.setState({ address });
  };

  // handleSelect = address => {
  //   geocodeByAddress(address)
  //     .then(results => getLatLng(results[0]))
  //     .then(latLng => console.log('Success', latLng))
  //     .catch(error => console.error('Error', error));
  // };

  renderGeocodeFailure(err) {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Error!</strong> {err}
      </div>
    );
  }

  renderGeocodeSuccess(lat, lng) {
    return (
      <div className="alert alert-success" role="alert">
        <strong>Success!</strong> Geocoder found latitude and longitude:{' '}
        <strong>
          {lat}, {lng}
        </strong>
      </div>
    );
  }

  render() {
    // const cssClasses = {
    //   root: 'form-group',
    //   input: 'Demo__search-input',
    //   autocompleteContainer: 'Demo__autocomplete-container'
    // };

    // const AutocompleteItem = ({ formattedSuggestion }) => (
    //   <div className="Demo__suggestion-item">
    //     <i className="fa fa-map-marker Demo__suggestion-icon" />
    //     <strong>{formattedSuggestion.mainText}</strong>{' '}
    //     <small className="text-muted">
    //       {formattedSuggestion.secondaryText}
    //     </small>
    //   </div>
    // );

    // const inputProps = {
    //   type: 'text',
    //   value: this.state.address,
    //   onChange: this.handleChange,
    //   onBlur: () => {
    //     console.log('Blur event!');
    //   },
    //   onFocus: () => {
    //     console.log('Focused!');
    //   },
    //   autoFocus: true,
    //   placeholder: 'Search Address',
    //   name: 'Demo__input',
    //   id: 'my-input-id'
    // };

    return (
      <div style={{flex:1, zIndex: 109}} >
       
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div>
                <BInput
                  {...getInputProps({
                    placeholder: '1600 Pensylvania Ave NY, Washington DC',
                    className: 'location-search-input'
                  })}
                  style={{width: '100%', fontSize: this.props.fontSize || 18, fontWeight: 500, lineHeight: 3, border: 'none', borderBottom: '.5px solid black' }}
                />
                <div className="autocomplete-dropdown-container" style={{position:'relative'}}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer', fontSize: this.props.fontSize || 20}
                      : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: this.props.fontSize || 20 };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {this.state.loading ? (
            <div>
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
            </div>
          ) : null}
          {!this.state.loading && this.state.geocodeResults ? (
            <div className="geocoding-results">{this.state.geocodeResults}</div>
          ) : null}
       
      </div>
    );
  }
}

export default AddressInput;
