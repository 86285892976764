import React from 'react'

const noop = () => {}

const EndorsementListItem = ({name, role, quote, remove=noop}, i) => (
  <li key={i}>
    
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
            <div className="">
              <div>
                <div className="text-sm leading-5 text-gray-600">
                  {quote}
                </div>
                <div className="mt-2 flex items-center text-lg leading-5 text-gray-800">
                  {name} 
                </div>
                <div className="flex items-center text-md leading-5 text-blue-400">
                  {role} 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg onClick={remove} className="h-5 w-5 text-gray-400 hover:text-red-400" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
          </svg>
        </div>
      </div>
  </li>
)

const PressListItem = ({title, publication, url, remove=noop}, i) => (
  <li key={i}>
    
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
            <div className="">
              <div>
                <div className="text-sm leading-5 text-gray-600">
                  {title}
                </div>
                { url.length === 0 ? 
                  (<div className="mt-2 flex items-center text-lg leading-5 text-gray-800">
                    {publication} 
                  </div>) :
                  (<div className="mt-2 flex items-center text-lg leading-5 text-gray-800">
                    <a href={url} rel="noopener noreferrer" target='_blank'> {publication} </a> 
                  </div>)
                }
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg onClick={remove} className="h-5 w-5 text-gray-400 hover:text-red-400" fill="currentColor" viewBox="0 0 20 20" >
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/>
          </svg>
        </div>
      </div>
    
  </li>
)

const campaignConfig = [
  {
    title: 'Pitch',
    info: 'The leading elevator pitch for the campaign.',
    inputs: [
      {
        label: 'Pitch',
        name: 'pitch',
        type: 'TextArea',
        style: {height: 150}
      }
    ]
  },
  {
    title: 'Profile Photo',
    info: 'Candidate photograph.',
    inputs: [
      {
        label: '',
        name: 'photo',
        type: 'Photo',
        style: {height: 150}
      }
    ]
  },
  {
    title: 'Issues',
    reqPayment: true,
    info: 'The list of two to three word isses that are most vital to the campaign.',
    inputs: [
      {
        label: 'Issues',
        name: 'issues',
        type: 'TextArray',
        placeholder: 'The Economy',
        buttonText: 'Add'

      }
    ]
  },
  {
    title: 'Video Lead',
    reqPayment: true,

    info: 'Embed a video to articulate the message of the campaign.',
    inputs: [
      {
        label: 'Embed Url',
        name: 'video',
        type: 'VideoLink',
        placeholder: 'https://www.youtube.com/embed/oHg5SJYRHA0'
      }
    ]
  },
  {
    title: 'Platform',
    reqPayment: true,
    info: 'Longform explanation of the campaign\'s platform.',
    inputs: [
      {
        label: 'Platform',
        name: 'platform',
        type: 'TextArea',
        style: {height: 300}
      }
    ]
  },
  {
    title: 'Endorsements',
    reqPayment: true,
    info: 'Offical endorsements from public figures.',
    inputs: [
      {
        label: '',
        name: 'endorsements',
        type: 'ObjectArray',
        addText: 'Add Endorsement',
        list: EndorsementListItem,
        inputs: [
          {
            label: 'Name',
            name: 'name',
            type: 'Text',
            classes: 'col-span-6 sm:col-span-6 lg:col-span-3',
            required: true
          },
          {
            label: 'Role',
            name: 'role',
            type: 'Text',
            classes: 'col-span-6 sm:col-span-6 lg:col-span-3',
            
          },
          {
            label: 'Quote',
            name: 'quote',
            type: 'TextArea',
            required: true
          }
        ]
      }
    ]
  },
  {
    title: 'Press',
    reqPayment: true,
    info: 'Offical endorsements from public figures.',
    inputs: [
      {
        label: '',
        name: 'press',
        type: 'ObjectArray',
        addText: 'Add press',
        list: PressListItem,
        inputs: [
          {
            label: 'Publication',
            name: 'publication',
            type: 'Text',
            required: true
          },
          {
            label: 'URL',
            name: 'url',
            type: 'Text'
          },
          {
            label: 'Title / Quote',
            name: 'title',
            type: 'TextArea',
            required: true
          },
        ]
      }
    ]
  },
  {
    title: 'Channels',
    reqPayment: true,
    info: 'Channels of communication to the public, social media and online presence.',
    inputs:  [
      {
        label: 'Website',
        name: 'url',
        type: 'TextPrefix',
        prefix: 'http://',
        placeholder: 'www.example.com'
      },
      {
        label: 'Twitter',
        name: 'twitter',
        type: 'TextPrefix',
        prefix: 'twitter.com/',
        placeholder: 'RealDonaldTrump'
      },
      {
        label: 'Faceboook',
        name: 'faceboook',
        type: 'TextPrefix',
        prefix: 'faceboook.com/',
        placeholder: 'john.doe'
      },
      {
        label: 'Youtube',
        name: 'youtube',
        type: 'TextPrefix',
        prefix: 'youtube.com/',
        placeholder: 'pewdiepie'
      }
    ]
  },
  {
    title: 'Candidate Contact',
    reqPayment: true,
    info: 'Basic public contact information for the candidate.',
    inputs: [
      {
        label: 'Email',
        name: 'email',
        type: 'Email',
        placeholder: 'john.doe@example.gove',
        classes: 'col-span-6 sm:col-span-6 lg:col-span-3'

      },
      {
        label: 'Phone',
        name: 'phones',
        type: 'Text',
        classes: 'col-span-6 sm:col-span-6 lg:col-span-3'
      },
      {
        label: 'Street Adress',
        name: 'address_1',
        type: 'Text'
      },
      {
        label: 'City',
        name: 'address_2',
        type: 'Text',
        classes: 'col-span-6 sm:col-span-6 lg:col-span-2'
      },
      {
        label: 'State',
        name: 'address_3',
        type: 'Text',
        classes: 'col-span-6 sm:col-span-6 lg:col-span-2'
      },
      {
        label: 'Zipcode',
        name: 'address_4',
        type: 'Text',
        classes: 'col-span-6 sm:col-span-6 lg:col-span-2'
      }
    ]
  },

]




export default campaignConfig;
