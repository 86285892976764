import React, { Component } from 'react';
import 'styles/tailwind.css';

import Layout from 'pages/Auth/components/Layout'

import CampaignPayment from './components/campaign/CampaignPayment'

class EditCampaign extends Component {
  render () {
    const headerMenu = {
      title: 'Edit Campaign', 
      items: [
        {
          name: 'Edit',
          to:  `/candidate/campaigns/edit/${this.props.match.params.campaignId}`
         
        },
        {
          name: 'Preview',
          to:  `/candidate/campaigns/${this.props.match.params.campaignId}`
           
        },
        {
          name: 'Status',
          to:  `/candidate/campaigns/status/${this.props.match.params.campaignId}`,
          active: true
        }
      ]
    } 

    return (
      <Layout headerMenu={headerMenu}>
        <CampaignPayment campaignId={this.props.match.params.campaignId} />
      </Layout>
   
    )
  }
}


export default
{
  name: 'Edit Campaign',
  path: '/candidate/campaigns/status/:campaignId',
  mainNav: false,
  authed: true,
  authLevel: 0,
  component: EditCampaign
}