import React, { Component } from 'react';
import 'styles/tailwind.css';

import Layout from 'pages/Auth/components/Layout'

import CampaignList from './components/campaign/CampaignList'

class Dashboard extends Component {
  render () {
    return (
      <Layout>
        <CampaignList />
      </Layout>
   
    )
  }
}

export default
{
  name: 'Dashboard',
  path: '/candidate/campaigns',
  mainNav: false,
  exact: true,
  authed: true,
  authLevel: 0,
  component: Dashboard
}