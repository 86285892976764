import React from "react"


export const IFrame = ({data, ...rest}) => (
	<div {...rest} style={{ position: 'relative', paddingBottom: '56.25%', paddingTop: 30, height: 0, overflow: 'hidden' }}>
		{typeof data === 'string' ? (
      		<iframe title='campaignVideo' src={data} frameBorder="0" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />) : ''
		}
    </div>
)


